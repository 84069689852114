"use client";
import axios from "axios";
import { baseURL } from "./Config/Environment";

const adsPlatformAxios = axios.create({
  baseURL: baseURL,
});

adsPlatformAxios.interceptors.request.use(async (req) => {
  const token = await localStorage.getItem("access_token");
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});

adsPlatformAxios.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    throw err;
  }
);

export default adsPlatformAxios;
