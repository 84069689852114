import { useEffect } from "react";
import Briefing from "./components/Briefing/Briefing";
import Clients from "./components/Clients/Clients";
import End from "./components/End/End";
import GCG from "./components/GCG/GCG";
import GetToKnow from "./components/GetToKnow/GetToKnow";
import GudangKreativ from "./components/GudangKreativ.js/GudangKreative";
import Narrative from "./components/Narrative/Narrative";
import Newsroom from "./components/Newsroom/Newsroom";
import PageScroll from "./components/PageScroll/PageScroll";
import Partners from "./components/Partners/Partners";
import Showreel from "./components/Showreel/Showreel";
import StockGroup from "./components/StockGroup/StockGroup";
import StrategicPartnership from "./components/StrategicPartnership/StrategicPartnership";
import Team from "./components/Team/Team";
import Upcoming from "./components/Upcoming/Upcoming";
import WhatWeDo from "./components/WhatWeDo/WhatWeDo";
import WhyHireUs from "./components/WhyHireUs/WhyHireUs";

export default function Home() {
  useEffect(() => {
    // document.title = "Home | FUTURPHUTURE - PT Lini Imaji Kreasi Ekosistem"
  }, []);

  return (
    <>
      <Briefing />
      <WhyHireUs />
      {/* <Narrative /> */}
      {/* <PageScroll /> */}
      <WhatWeDo />
      {/* <GetToKnow /> */}
      <Showreel />
      <Clients />
      <Team />
      <GCG />
      <Newsroom />
      <Upcoming />
      <GudangKreativ />
      <StrategicPartnership />
      {/* <Partners /> */}
      <StockGroup />
      <End />
    </>
  );
}
