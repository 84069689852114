import logo from "assets/images/logo_futr.png";
import { Link } from "react-router-dom";
import style from "./FooterLinks.module.css";
import nabungSaham from "assets/images/logo_nabung_saham.png";
import idx from "assets/images/logo_idx.png";

export default function FooterLinks() {
  return (
    <div className="container">
      <div className={style.section}>
        <div className={style["section-left"]}>
          <img src={logo} />
          <div className={style.copyright}>
            &copy; 2022 PT LINI IMAJI KREASI EKOSISTEM, Tbk
          </div>
        </div>
        <div className={style["section-right"]}>
          <img className={style["saham"]} src={nabungSaham} alt="" />
          <img className={style["idx"]} src={idx} alt="" />
          <span className={`BakbakOne ${style["code"]}`}>IDX: FUTR</span>
        </div>
      </div>
    </div>
  )
}