import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import AppContext from "AppContext";
import style from "./HQ.module.css";
import { data_hq } from "../data";

export default function HQ() {
  const { baseUrl } = useContext(AppContext);
  const [data, setData] = useState({});

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/hq`);
    // const result = await fetchResult.json();
    const result = data_hq;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className={style["title"]}>
        <img src="/assets/images/icon_pinpointmap_darkgrey.png" />
        <h4>{data.title}</h4>
      </div>
      <div className={style["container"]}>
        {data.locations && data.locations.map((location, index) =>
          <div className={style["location"]} key={index}>
            <h4 className="fw-700 fs-24">{location.label}</h4>
            <div className={style["description"]}>
              <ReactMarkdown>
                {location.value}
              </ReactMarkdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
