import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import style from "./Management.module.css";
import structure from "assets/images/company_structure.png";
import { commissioners_en, commissioners_id, directors_en, directors_id } from "./data";

export default function Management() {
  const [directors, setDirectors] = useState([]);
  const [commissioners, setCommissioners] = useState([]);
  const {baseUrl, lang} = useContext(AppContext);
  
  const fetchDirectors = async () => {
    // const fetchResult = await fetch(`${baseUrl}/directors?_locale=${lang}&_sort=id`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? directors_id : directors_en;
    setDirectors(result);
  }
  const fetchCommissioners = async () => {
    // const fetchResult = await fetch(`${baseUrl}/commissioners?_locale=${lang}&_sort=id`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? commissioners_id : commissioners_en;
    setCommissioners(result);
  }

  useEffect(() => {
    fetchDirectors();
    fetchCommissioners();
  }, [lang]);

  return (
    <>
      <div className={style.top}></div>
      <div className={style.container}>
        <div className="container">
          <h3 className={`${style.title} Exo2 fs-36`}>
            <span className={style["highlight"]}>Management</span> & Structure&nbsp;
            <strong>PT LINI KREASI IMAJI EKOSISTEM</strong>
          </h3>

          <h5 className={`${style.subtitle} BakbakOne fs-18`}>Board of Commissioners</h5>
          <div className={style["board-container"]}>
            {
              commissioners.map(commissioner => (
                <div className={style["board-member"]} key={commissioner.id}>
                  <div className={style["image-container"]}>
                    {/* <img src={`${baseUrl}${commissioner.profile_picture.url}`} className={style["member-image"]} /> */}
                    <img src={`${commissioner.profile_picture.url}`} className={style["member-image"]} />
                    {/* <img src={`${baseUrl}${commissioner.background_picture.url}`} /> */}
                    <img src={`${commissioner.background_picture.url}`} />
                    <label className={`${style["member-name"]} fs-24`}>{commissioner.name}</label>
                  </div>
                  <label className={`${style["member-position"]} fs-14`}>{commissioner.position}</label>
                </div>
              ))
            }
          </div>

          <h5 className={`${style.subtitle} BakbakOne fs-18`}>Board of Directors</h5>
          <div className={style["board-container"]}>
            {
              directors.map(director => (
                <div className={style["board-member"]} key={director.id}>
                  <div className={style["image-container"]}>
                    {
                      director.profile_picture && director.background_picture && (
                        <>
                          {/* <img src={`${baseUrl}${director.profile_picture.url}`} className={style["member-image"]} /> */}
                          <img src={`${director.profile_picture.url}`} className={style["member-image"]} />
                          {/* <img src={`${baseUrl}${director.background_picture.url}`} /> */}
                          <img src={`${director.background_picture.url}`} />
                        </>
                      )
                    }
                    <label className={`${style["member-name"]} fs-24`}>{director.name}</label>
                  </div>
                  <label className={`${style["member-position"]} fs-14`}>{director.position}</label>
                </div>
              ))
            }
          </div>

          <h5 className={`${style.subtitle} BakbakOne fs-18`}>Company Structure</h5>
          <div className={style["board-container"]}>
            <div className={`${style["company-structure-wrapper"]} img-wrapper`}>
              <img src={structure} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}