import { API_TOKEN } from "services/Config/Environment";
import adsPlatformAxios from "services/FutrAxios";

export const api =
  "8a3c746b2643331ca9d7874cab0c760bd46108f06468ec2077abbbda8933c29c";

export const getListHighlightNews = async () => {
  try {
    return await adsPlatformAxios({
      method: "GET",
      url: `/article`,
      params: {
        page: 1,
        api_token: api,
      },
    });
  } catch (error) {
    throw error;
  }
};
