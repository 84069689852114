import style from "./FloatingButtons.module.css";
import iconDownloadWhite from "assets/images/icon_download.png";
import iconDownloadBlack from "assets/images/icon_download_black.png";
import iconWhatsapp from "assets/images/icon_whatsapp_circle.png";
import { useEffect, useState } from "react";
import { HiOutlineDownload } from "react-icons/hi";
// import { HiArrowUpRight } from "react-icons/hi2";
import Modal from "../Modal/Modal";
import toast from "react-hot-toast";
// import AppContext from "AppContext";
import { data_en } from "./data";

export default function FloatingButtons() {
  const [showModal, setShowModal] = useState(false);
  const [showDownloadButtons, setShowDownloadButtons] = useState(false);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [data, setData] = useState({});
  // const { baseUrl, lang } = useContext(AppContext);

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/about-company?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = data_en;
    setData(result);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  }
  const toggleShowDownloadButtons = () => {
    setShowDownloadButtons(!showDownloadButtons);
  }

  const sendEmail = () => {
    setIsDisabled(true);
    setisSubmitting(true);
    fetch("https://futr.asia/api/emails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email: "hello@futrphuture.io",
        company_name: companyName,
        position,
        user_email: email,
      }),
    })
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      toast.success("Email sent successfully.");
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      setShowModal(false);
      setisSubmitting(false);
      setName("");
      setCompanyName("");
      setPosition("");
      setEmail("");
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (name && companyName && position && email && emailRegex.test(email)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [name, companyName, position, email]);


  return (<>
    <div className={style["container"]}>
      <div className={style["btn-download-group"]}>
        <div className={`${style["btn-floating"]} ${style["btn-toggler"]}`} onClick={toggleShowDownloadButtons}>
          <div className={`${style["img-wrapper"]} ${style["icon-white"]}`}>
            <img src={iconDownloadWhite} alt="" />
          </div>
          <div className={`${style["img-wrapper"]} ${style["icon-black"]}`}>
            <img src={iconDownloadBlack} alt="" />
          </div>
        </div>
        {showDownloadButtons && <div className={style["btn-downloads"]}>
          {/* {data.company_profile_file &&
            <a href={`${baseUrl}${data.company_profile_file.url}`} className={style["btn-download"]} download={true}>
              <HiOutlineDownload /> <span>Download</span> Company Profile
            </a>
          }
          <a className={style["btn-download"]} onClick={() => setShowModal(true)}>
            <HiArrowUpRight /> <span>Download</span> Company Briefing
          </a> */}
          {data.company_briefing_file &&
            // <a href={`${baseUrl}${data.company_briefing_file.url}`} className={style["btn-download"]} download={true}>
            <a href={`${data.company_briefing_file.url}`} className={style["btn-download"]} download={true}>
              <HiOutlineDownload /> <span>Download</span> Company Briefing
            </a>
          }
          <a href="/uploads/Final_Report_FS_1Q_FUTR_31_Maret_2023.pdf" className={style["btn-download"]} download={true}>
            <HiOutlineDownload /> <span>Download</span> 2023-Q1 Financial Report
          </a>
        </div>}
      </div>
      <a className={style["btn-floating"]} target="_blank" rel="noreferrer" href="https://wa.me/6285216643768">
        <div className={`${style["img-wrapper"]}`}>
          <img src={iconWhatsapp} alt="" />
        </div>
      </a>
    </div>
    <Modal show={showModal} onClose={handleCloseModal}>
      <div className={style["modal-title"]}>
        Please fill in this form and we will email you shortly.
      </div>
      <div>
        <input
          type="text"
          className={style["modal-input"]}
          placeholder="Your Name"
          required={true}
          onChange={(e) => {setName(e.target.value)}}
        />
        <input
          type="text"
          className={style["modal-input"]}
          placeholder="Company Name"
          required={true}
          onChange={(e) => {setCompanyName(e.target.value)}}
        />
        <input
          type="text"
          className={style["modal-input"]}
          placeholder="Your Position"
          required={true}
          onChange={(e) => {setPosition(e.target.value)}}
        />
        <input
          type="email"
          className={style["modal-input"]}
          placeholder="Your Email"
          required={true}
          onChange={(e) => {setEmail(e.target.value)}}
        />
        <button
          className={style["modal-button"]}
          onClick={sendEmail}
          disabled={isDisabled}
        >
          {isSubmitting && "Submitting..."}
          {!isSubmitting && "Submit"}
        </button>
      </div>
    </Modal>
  </>)
}