import AppContext from "AppContext";
import logo from "assets/images/logo_futr.png";
import { useContext } from "react";
import { Link } from "react-router-dom";
import style from "./Header.module.css";
import { useEffect } from "react";

export default function Header() {
  const langs = ['en', "id"];
  const { baseUrl, lang, setLang } = useContext(AppContext);
  const handleLangChange = (value) => {
    setLang(value);
    localStorage.setItem('futrlang', JSON.stringify(value));
  }

  useEffect(() => {
    const localLang = JSON.parse(localStorage.getItem('futrlang'));
    if (localLang && langs.includes(localLang)) {
      setLang(localLang);
    } else {
      handleLangChange("id");
    }
  }, []);

  return (
    <div className={style["container"]}>
      <Link to="/" className={style["logo"]}>
        <img src={logo} />
      </Link>
      <div className={style["lang-selector"]}>
        {langs.map((item, index) =>
          <span className={lang === item ? style["active"] : ""} onClick={() => handleLangChange(item)} key={index}>
            {item}
          </span>
        )}
      </div>
    </div>
  )
}