import { useContext, useEffect, useState } from "react";
import style from "./WhatWeDo.module.css";
// import pin from "assets/images/icon_pin.png";
// import emo from "assets/images/icon_think_emo.png";
import { FaEnvelope, FaWhatsapp } from "react-icons/fa";
import AppContext from "AppContext";


export default function ModalRightStep1({
  showModal,
  onSubmit,
  name,
  phoneNumber,
  email,
  onNameChange,
  onPhoneNumberChange,
  onEmailChange
}) {
  // const [name, setName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [email, setEmail] = useState("");
  const {lang} = useContext(AppContext);
  const [showToggledSkyflow, setShowToggledSkyflow] = useState(false);
  const [showToggledFaq, setShowToggledFaq] = useState(false);
  const [scheduleButtonDisabled, setScheduleButtonDisabled] = useState(true);

  const toggleShowSkyflow = () => {
    setShowToggledSkyflow(!showToggledSkyflow);
  }

  const toggleShowFaq = () => {
    setShowToggledFaq(!showToggledFaq);
  }

  useEffect(() => {
    const phoneTest = phoneNumber.match(/\d/g);
    const validPhone = phoneTest && phoneTest.length >= 7 && phoneTest.length <= 15;
    const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    setScheduleButtonDisabled(!(name && validPhone && validEmail));
  }, [name, email, phoneNumber]);

  useEffect(() => {
    // console.log("resetting fields");
    // setName("");
    // setPhoneNumber("");
    // setEmail("");
  }, [showModal]);

  return (<>
    <div className={style["form-row"]}>
      <label>
        {lang === "id" ? "Halo, nama saya" : "Hello, My name is"}
      </label>
      <div className={style["input-wrapper"]}>
        <input type="text" value={name} onChange={(e) => onNameChange(e.target.value)} />
      </div>
      <label>
        {lang === "id" ? "kamu bisa menghubungi saya melalui" : "you can reach me through"}
      </label>
    </div>
    <div className={style["form-row"]}>
      <div className={style["input-wrapper"]}>
        <FaEnvelope />
        <input type="email" value={email} onChange={(e) => onEmailChange(e.target.value)} />
      </div>
      <label>
        {lang === "id" ? "dan" : "and"}
      </label>
      <div className={style["input-wrapper"]}>
        <FaWhatsapp />
        <input type="number" value={phoneNumber} onChange={(e) => onPhoneNumberChange(e.target.value)} />
      </div>
    </div>
    {/* <div className={style["agreement"]}>
      <div className={style["agreement-item"]}>
        <div className={style["agreement-header"]}>
          <img src={pin} alt="" />
          You are protected by <span>Skyflow</span>
          <div className={style["agreement-toggler"]} onClick={toggleShowSkyflow}>
            {showToggledSkyflow ? "-" : "+"}
          </div>
        </div>
        {showToggledSkyflow && <div className={style["agreement-content"]}>
          Search, analyze, and use sensitive data while keeping that data always encrypted within the vault. Skyflow Data Privacy Vault delivers more than just tokenization.
        </div>}
      </div>

      <div className={style["agreement-item"]}>
        <div className={style["agreement-header"]}>
          <img src={emo} alt="" />
          Read our FAQs
          <div className={style["agreement-toggler"]} onClick={toggleShowFaq}>
            {showToggledFaq ? "-" : "+"}
          </div>
        </div>
        {showToggledFaq && <div className={style["agreement-content"]}>
          content goes here
        </div>}
      </div>
    </div> */}

    <button type="button"
      className={style["button-submit"]}
      disabled={scheduleButtonDisabled}
      onClick={onSubmit}
    >
      {lang === "id" ? "Atur jadwal pertemuan" : "Schedule a meeting"}
    </button>
  </>)
}