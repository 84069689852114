import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import style from "./WhatWeDo.module.css";

export default function ModalLeft({data}) {
  return (
    <div className={`${style["modal-content-wrapper"]} ${style["modal-left"]}`}>
      <div className={style["modal-content"]}>
        <h4 className={`${style["modal-title"]} ${style["sector-title"]}`}>{data.item.name}</h4>
        <p className={style["sector-parent"]}>in {data.parent}</p>
        {/* <div className={style["sector-pricing"]}>
          Pricing starts from: 10,000,000
        </div> */}
        <div className={style["sector-description"]}>
          <ReactMarkdown>
            {data.item.description}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  )
}