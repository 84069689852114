import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import style from "./VisionMission.module.css";
import { data_en, data_id } from "./data";

export default function VisionMission() {
  const [vision, setVision] = useState({});
  const [mission, setMission] = useState({});
  const [gcg, setGCG] = useState({});
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/vision-mission?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setVision(result.vision);
    setMission(result.mission);
    setGCG(result.gcg);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={style["container"]}>
      <div className="container">
        <h1 className={`BakbakOne ${style["title"]}`}>
          {gcg.label}
        </h1>
        <div className={style.flex}>
          <div className="col-md-6">
            <h4 className="fs-36 Exo2 d-inline">
              {vision.label}
            </h4>
            <div className="fs-24">
              <ReactMarkdown>
                {vision.value}
              </ReactMarkdown>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="fs-36 Exo2 d-inline">
              {mission.label}
            </h4>
            <div className="fs-24">
              <ReactMarkdown>
                {mission.value}
              </ReactMarkdown>
              {/* {`Optimize data, technology and human-centric designed solutions to help international brands and local enterprises to grow their revenue and profits. `} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
