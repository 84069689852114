import { isSaturday, isSunday } from "date-fns";
import { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import style from "./WhatWeDo.module.css";
import "./day-picker.css";
import { FaClock } from "react-icons/fa";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "AppContext";

export default function ModalRightStep2({showModal, onSubmit, isSubmitting}) {
  const [selectedDate, setSelectedDate] = useState("");
  const [submitButtonLabel, setSubmitButtonLabel] = useState("");
  const today = new Date();
  const {lang} = useContext(AppContext);
  const disabledDates = [
    {before: new Date().setDate(new Date().getDate() + 2)},
    isSaturday,
    isSunday
  ]

  useEffect(() => {
    setSelectedDate("");
  }, [showModal]);

  useEffect(() => {
    if (lang === "id") {
      setSubmitButtonLabel(isSubmitting ? "Diproses..." : "Jadwalkan pertemuan");
    } else if (lang === "en") {
      setSubmitButtonLabel(isSubmitting ? "Submitting..." : "Set meeting");
    }
  }, [lang, isSubmitting])

  return <>
    <div className={style["step2-container"]}>
      <div className={style["step2-description"]}>
        <h5 className={style["step2-title"]}>
          {lang === "id" ? "Perkenalan dengan FUTURPHUTURE" : "Introduction call with FUTURPHUTURE"}
        </h5>
        <div className={style["step2-duration"]}>
          <FaClock /> 30 {lang === "id" ? "menit" : "mins"}
        </div>
        {/* <div>Agenda:</div>
        <ol className={style["step2-agenda"]}>
          <li>Introduction</li>
          <li>Requirement understanding</li>
          <li>Similar work showcase</li>
          <li>Next actions</li>
        </ol> */}
      </div>
      <div className={style["step2-calendar"]}>
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={setSelectedDate}
          // disabled = {{ before: today }}
          disabled = {disabledDates}
        />
      </div>
    </div>
    <button type="button"
      className={style["button-submit"]}
      onClick={() => onSubmit(selectedDate)}
      disabled={selectedDate === "" || isSubmitting}
    >
      {submitButtonLabel}
    </button>
  </>
}