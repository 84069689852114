import React, { useContext, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import style from "./Clients.module.css";
import AppContext from "AppContext";
import { data_en } from "./data";

export default function Clients() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/clients?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = data_en
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <>
      {data && <div>
        <div className={style["container"]}>
          <span className={style["title"]}>
            <span className={style["highlight"]}>
              {data.highlighted_title}
            </span>
            <span>
              {data.unhighlighted_title}
            </span>
          </span>
        </div>
        <div style={{ marginTop: "0.5em", marginBottom: "0.5em" }}>
          <Carousel
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={3000}
            showStatus={false}
            swipeable={true}
            dynamicHeight={true}
            renderIndicator={(clickHandler, selected) => {
              return (
                <div
                  onClick={clickHandler}
                  style={{
                    backgroundColor: selected ? "#CAFC0E" : "#333",
                    padding: "0.1em",
                    borderBottom: "1em solid " + selected ? "#CAFC0E" : "#333",
                    marginRight: "0.5em",
                    width: "1.5em",
                    display: "inline-block",
                    cursor: "pointer"
                  }}
                ></div>
              );
            }}
          >
            {data.media.map((slide, index) =>
              <div className={style["slide-wrapper"]} key={index}>
                {/* <img src={`${baseUrl}${slide.url}`} /> */}
                <img src={`${slide.url}`} />
              </div>
            )}
          </Carousel>
        </div>
      </div>}
    </>
  );
}
