import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import style from "./CorporateSecretary.module.css";
import icon from "assets/images/icon_document.png";
import { data_en } from "./data";

export default function CorporateSecretary() {
  const [secretary, setSecretary] = useState({});
  const {baseUrl, lang} = useContext(AppContext);
  
  const fetchSecretary = async () => {
    // const fetchResult = await fetch(`${baseUrl}/corporate-secretary`);
    // const result = await fetchResult.json();
    const result = data_en;
    setSecretary(result);
  }

  useEffect(() => {
    fetchSecretary();
  }, []);

  return (
    <div className={style.container}>
      <div className="container">
        <h3 className={`${style.title} Exo2 fs-36`}>
          <span className={style["highlight"]}>CORPORATE SECRETARY</span>
        </h3>
        <div className={style["board-container"]}>
          <div className={style["board-member"]}>
            <div className={style["image-container"]}>
              {
                secretary.profile_picture && secretary.background_picture &&
                (<>
                  {/* <img src={`${baseUrl}${secretary.profile_picture.url}`} className={style["member-image"]} /> */}
                  <img src={`${secretary.profile_picture.url}`} className={style["member-image"]} />
                  {/* <img src={`${baseUrl}${secretary.background_picture.url}`} /> */}
                  <img src={`${secretary.background_picture.url}`} />
                </>)
              }
              <label className={`${style["member-name"]} fs-24`}>{secretary.name}</label>
            </div>
            <label className={`${style["member-position"]} fs-14`}>
              Corporate Secretary
            </label>
          </div>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a href="/assets/media/PENGANGKATAN_CORSEC_1.pdf" className="name fw-700">
                {lang == "en" ? "CORPORATE SECRETARY DECREE" : "PENGANGKATAN CORSEC"}  [PDF | 583 KB]
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}