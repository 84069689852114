import style from "./WhyHireUs.module.css";
import line from "assets/images/bg_yellow_line_hire_us.png";
import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { data_en, data_id } from "./data";

export default function WhyHireUs() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/why-client-hire-us?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={`bgc-darkgrey ${style["container"]}`}>
      <img src={line} className={style["bg-line"]} />
      {data && <>
        <h3 className={style["title"]}>
          <span className={style["highlight"]}>
            {data.highlighted_title}
          </span>
          <span>
            {data.unhighlighted_title}
          </span>
        </h3>
        
        <div className={style["reasons"]}>
          {data.reasons.map((reason, index) =>
            <div className={style["reason"]} key={index}>
              <ReactMarkdown>
                {reason.item}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </>}
    </div>
  )
}