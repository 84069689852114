import AppContext from "AppContext";
import React, { useContext, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import style from "./StrategicPartnership.module.css";
import { data_en, data_id } from "./data";

export default function StrategicPartnership() {
  const {baseUrl, lang} = useContext(AppContext);
  const [data, setData] = useState({});
  const [leftPartners, setLeftPartners] = useState([]);
  const [rightPartners, setRightPartners] = useState([]);

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/strategic-partnership?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    const partnerships = result.partnerships;
    const divisor = Math.ceil(partnerships.length/2);
    setLeftPartners(partnerships.slice(0, divisor));
    setRightPartners(partnerships.slice(divisor));
    setData(result);
  }

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={`bgc-darkgrey ${style["container"]}`}>
      <h3 className={style["title"]}>
        {data.highlighted_title && <span className={style["highlight"]}>
          {data.highlighted_title}
        </span>}
        {data.unhighlighted_title && <span>
          {data.unhighlighted_title}
        </span>}
      </h3>
      
      <div className={style["partner-list"]}>
        <div className={style["partner-left"]}>
          {leftPartners.map((partner, index) =>
            <div className={style["partner"]} key={`left_${index}`}>
              <div className={style["logo-wrapper"]}>
                {/* <img src={`${baseUrl}${partner.media.url}`} /> */}
                <img src={`${partner.media.url}`} />
              </div>
              <div>
                <ReactMarkdown>
                  {partner.value}
                </ReactMarkdown>
              </div>
            </div>
          )}
        </div>
        <div className={style["partner-right"]}>
          {rightPartners.map((partner, index) =>
            <div className={style["partner"]} key={`right_${index}`}>
              <div className={style["logo-wrapper"]}>
                {/* <img src={`${baseUrl}${partner.media.url}`} /> */}
                <img src={`${partner.media.url}`} />
              </div>
              <ReactMarkdown>
                {partner.value}
              </ReactMarkdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
