import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import style from "./Contact.module.css";
import IconTalk from "assets/images/icon_talk_2.png";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { data_contact } from "../data";

export default function Contact() {
  const { baseUrl } = useContext(AppContext);
  const [contact, setContact] = useState({});

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/contact`);
    // const result = await fetchResult.json();
    const result = data_contact;
    setContact(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={style["container"]}>
      <div>
        <div className={style["title"]}>
          <img src={IconTalk} />
          <h4 className="fw-700 fs-24">Let's Connect!</h4>
        </div>
        <div className={style["social-container"]}>
          {contact.social_medias && contact.social_medias.map((social, index) =>
            <a className={style["social"]} href={social.value} target="_blank" key={index}>
              {/* <img src={`${baseUrl}${social.media.url}`} /> */}
              <img src={`${social.media.url}`} />
            </a>
          )}
        </div>
        <div className={style["description"]}>
          <ReactMarkdown>
            {contact.content}
          </ReactMarkdown>
          {/* <div>
            T: +62 217 659 237 <br />
            E:{" "}
            <span
              style={{ color: "#6C36FE" }}
              onClick={() => {
                window.open("mailto:hello@futrphuture.io");
              }}
            >
              hello@futrphuture.io
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
}
