import style from "./Team.module.css";
import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { data_en, data_id } from "./data";

export default function Team() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/team?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (<>
    {data && <div className={`bgc-purple2 text-white ${style["container"]}`}>
      <h1 className={`BakbakOne ${style["title"]}`}>
        <span className={style["highlight"]}>
          THE
          {/* {data.highlighted_title} */}
        </span>
        <strong>FUTR</strong> TEAM
        {/* {data.unhighlighted_title} */}
      </h1>
      <div className={style["content"]}>
        <div className={style["content-top"]}>
          <ReactMarkdown>
            {data.description_top}
          </ReactMarkdown>
        </div>
        <div className={style["content-bottom"]}>
          <ReactMarkdown>
            {data.description_bottom}
          </ReactMarkdown>
        </div>
      </div>
    </div>}
  </>)
}