import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";

const YoutubeSlide = ({ url, isSelected }) => {
  let windowWidth = window.innerWidth;
  let height = windowWidth / 16 * 9;
  if (height > window.innerHeight) {
    height = window.innerHeight;
  }
  return <ReactPlayer width="100%" height={height} url={url} playing={isSelected} muted={true} />
};

export default function Showreel() {
  const [origin, setOrigin] = useState("");

  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

  const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url) => url.substr('https://www.youtube.com/embed/'.length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url);
      return <img src={getVideoThumb(videoId)} />;
    });

  useEffect(() => {
    setOrigin(window.origin);
  }, []);

  return (
    <div className="fullwidth-carousel">
      <Carousel
        // autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        renderItem={customRenderItem}
        renderArrowPrev={(clickHandler) =>
          <button className="custom-carousel-arrow arrow-prev" onClick={clickHandler}></button>
        }
        renderArrowNext={(clickHandler) =>
          <button className="custom-carousel-arrow arrow-next" onClick={clickHandler}></button>
        }
        renderIndicator={(clickHandler, selected) => {
          return (
            <div
              style={{
                backgroundColor: selected ? "#CAFC0E" : "#333",
                padding: "0.1em",
                borderBottom: "1em solid " + selected ? "#CAFC0E" : "#333",
                marginRight: "0.5em",
                width: "1.5em",
                display: "inline-block",
                cursor: "pointer"
              }}
              onClick={clickHandler}
            ></div>
          );
        }}
      >
        <YoutubeSlide key="youtube-1" url={`https://www.youtube.com/embed/sRARELCQKIM?enablejsapi=1&origin=${origin}`} />
        <YoutubeSlide key="youtube-2" url={`https://www.youtube.com/embed/HOEm0Pmb4CM&t=3s?enablejsapi=1&origin=${origin}`} />
        <YoutubeSlide key="youtube-3" url={`https://www.youtube.com/embed/Ks4eiC3NtVY&t=4s?enablejsapi=1&origin=${origin}`} />
        <YoutubeSlide key="youtube-4" url={`https://www.youtube.com/embed/cxT8r4oVDjg?enablejsapi=1&origin=${origin}`} />
      </Carousel>
    </div>
  );
}