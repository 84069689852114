import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import style from "../KnowMore/KnowMore.module.css";
import selfStyle from "./TheOne.module.css";
import { data_en, data_id } from "./data";

export default function TheOne() {
  const { baseUrl, lang } = useContext(AppContext);
  const [data, setData] = useState({});

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/the-one?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div
      className={`${style["section-container"]} bgc-purple2 smooth-transition`}
    >
      <div className={style.section}>
        <div className={style["section-content"]}>
          <div className={`${style["section-content-inner"]} color-white`}>
            <h3
              className="BakbakOne text-center"
              style={{ marginBottom: "0.5em" }}
            >
              <ReactMarkdown>
                {data.title}
              </ReactMarkdown>
            </h3>
            <div className="smooth-transition text-center">
              {/* <img src="/assets/images/icon_chevronright_purple.png" /> */}
            </div>
            <div className={`${style["section-description"]} ${selfStyle["description"]}`}>
              <ReactMarkdown>
                {data.description}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
