import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";
import Modal from "../Modal/Modal";
import style from "./Cookies.module.css";

function Cookies() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
    <CookieConsent
      location="bottom"
      buttonText="GOT IT"
      cookieName="myAwesomeCookieName2"
      containerClasses={style["cookie-container"]}
      buttonClasses={style["cookie-accept"]}
      buttonWrapperClasses={style["cookie-button-wrapper"]}
      expires={150}
    >
      <p className={style["cookie-description"]}>
        This website uses cookies to ensure you get the best experience on our website.
      </p>
      <label className={style["cookie-learn"]} onClick={() => {setShowModal(true)}}>LEARN MORE</label>
    </CookieConsent>

    <Modal show={showModal} onClose={() => {setShowModal(false)}}>
      <h4 className={style["modal-title"]}>
        Cookie Policy
      </h4>
      <div className={style["modal-content"]}>
        <p>
          Please read this cookie policy (“cookie policy”, "policy") carefully before using FUTURPHUTURE website (“website”, "service") operated by PT LINI IMAJI KREASI EKOSISTEM Tbk ("us", 'we", "our").
        </p>
        <div className={style["modal-section-header"]}>
          What are cookies?
        </div>
        <p>
          Cookies are simple text files that are stored on your computer or mobile device by a website’s server. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier, website’s domain name, and some digits and numbers.
        </p>
        <div className={style["modal-section-header"]}>
          What types of cookies do we use?
        </div>
        
        <i>Necessary cookies</i>
        <p>
          Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our website and using its features. For example, these cookies let us recognize that you have created an account and have logged into that account.
        </p>
        
        <i>Functionality cookies</i>
        <p>
          Functionality cookies let us operate the site in accordance with the choices you make. For example, we will recognize your username and remember how you customized the site during future visits.
        </p>

        <i>Analytical cookies</i>
        <p>
          These cookies enable us and third-party services to collect aggregated data for statistical purposes on how our visitors use the website. These cookies do not contain personal information such as names and email addresses and are used to help us improve your user experience of the website.
        </p>

        <div className={style["modal-section-header"]}>
          How to delete cookies?
        </div>
        If you want to restrict or block the cookies that are set by our website, you can do so through your browser setting. Alternatively, you can visit www.internetcookies.com, which contains comprehensive information on how to do this on a wide variety of browsers and devices. You will find general information about cookies and details on how to delete cookies from your device.
        
        <div className={style["modal-section-header"]}>
          Contacting us
        </div>
        <p>
          If you have any questions about this policy or our use of cookies, please contact <a href="mailto:hello@futurphuture.io" target="_blank">hello@futrphuture.io</a>.
        </p>
      </div>
    </Modal>
  </>);
}

export default Cookies;
