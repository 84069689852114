import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import AppContext from "AppContext";
import style from "./End.module.css";
import image from "assets/images/decorator_end.png";
import Rectangle from "assets/images/rectangle.png";
import { data_en, data_id } from "./data";

export default function End() {
  const { baseUrl, lang } = useContext(AppContext);
  const [end, setEnd] = useState({});

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/end?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setEnd(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="bgc-yellow">
      <div className="container">
        <div className={`${style["content-container"]}`}>
          <div>
            {/* <div className={style.title}>
              <h4 className="fw-700">
                To End 
              </h4>
            </div> */}
            <div className={style.content}>
              <div className={style["content-1"]}>
                <ReactMarkdown>
                  {end.content}
                </ReactMarkdown>
              </div>
              <div className={style["content-2"]}>
                {end.be_brave}
              </div>
            </div>
          </div>
          {/* <img src={image} className={style.decorator} /> */}
        </div>
        <img
          src={Rectangle}
          // style={{ width: "25em", marginBottom: "-0.3em", marginLeft: "-3em" }}
          className={style["rectangle"]}
        />
      </div>
    </div>
  );
}
