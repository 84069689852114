// import './App.css';

import GoodCorporateGovernance from "pages/GCG/GoodCorporateGovernance";
import Home from "pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import Cookies from "shared/components/Cookies/Cookies";
import Footer from "shared/components/Footer/Footer";
import Header from "shared/components/Header/Header";
import Intro from "shared/components/Intro/Intro";
import toast, { Toaster } from "react-hot-toast";
import FloatingButtons from "shared/components/FloatingButtons/FloatingButtons";

function App() {
  return (
    <>
      <Toaster />
      {/* <Intro /> */}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gcg" element={<GoodCorporateGovernance />} />
      </Routes>
      <Footer />
      <FloatingButtons />
      <Cookies />
    </>
  );
}

export default App;
