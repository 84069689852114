import icon from "assets/images/icon_building.png";
import bgLines from "assets/images/bg_line_purple.png";
import { Link } from "react-router-dom";
import style from "./GCG.module.css";
import {HiArrowUpRight } from "react-icons/hi2";
import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import { data_en, data_id } from "./data";

export default function GCG() {
  const [data, setData] = useState({});
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/gcg?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={`${style.container} bgc-darkgrey`}>
      <div className={style["title-wrapper"]}>
        <img src={icon} />
        <h3 className={`BakbakOne ${style["title"]}`}>
          {data.title}
        </h3>
      </div>
      <div className={style["link-wrapper"]}>
        <Link to={"/gcg"} className={style["btn-link"]} id="btn-gcg">
          <span>{data.click_to_learn_more}</span>
          <HiArrowUpRight />
        </Link>
        <img src={bgLines} alt="" />
      </div>
    </div>
  );
}
