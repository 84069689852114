import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import style from "./Committee.module.css";
import icon from "assets/images/icon_document.png";
import decorator from "assets/images/decorator_committee.png";
import {
  committee_description_en,
  committee_description_id,
  committee_en,
  committee_id,
} from "./data";

export default function Committee() {
  const [committees, setCommittees] = useState([]);
  const [description, setDescription] = useState({});
  const { baseUrl, lang } = useContext(AppContext);

  const fetchCommittee = async () => {
    // const fetchResult = await fetch(`${baseUrl}/committees?_locale=${lang}&_sort=id`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? committee_id : committee_en;
    setCommittees(result);
  };

  const fetchDescription = async () => {
    // const fetchResult = await fetch(`${baseUrl}/committee-description?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result =
      lang === "id" ? committee_description_id : committee_description_en;
    setDescription(result);
  };

  useEffect(() => {
    fetchCommittee();
    fetchDescription();
  }, [lang]);

  return (
    <div className={style.container}>
      <div className="container">
        <div className={`${style.flex} d-flex`}>
          <div className={`${style["column-left"]} col-md-6`}>
            <h3 className={`${style.title} Exo2 fs-36`}>
              <span className={style["highlight"]}>COMMITTEE</span> + PROFILE
            </h3>
            <p>{description.description}</p>

            <h5 className={`${style.subtitle} fs-18 fw-400 BakbakOne`}>
              {lang == "en" ? "CHARTERS" : "PIAGAM"}
            </h5>
            <div className="document-wrapper">
              <div className="document">
                <div className="icon">
                  <img src={icon} />
                </div>
                <a
                  href="/assets/media/PIAGAM_KOMITE_AUDIT.pdf"
                  className="name fw-700"
                >
                  {lang == "en"
                    ? "AUDIT COMMITTEE CHARTER"
                    : "PIAGAM KOMITE AUDIT"}{" "}
                  [PDF | 2.47 MB]
                </a>
              </div>
            </div>

            <div className="document-wrapper">
              <div className="document">
                <div className="icon">
                  <img src={icon} />
                </div>
                <a
                  href="/assets/media/PEDOMAN_DAN_TATA_TERTIB_KERJA_KOMITE_NOMINASI_DAN_REMUNERASI.pdf"
                  className="name fw-700"
                >
                  {lang == "en"
                    ? "NOMINATION AND REMUNERATION COMMITTEE CHARTER"
                    : "PEDOMAN DAN TATA TERTIB KERJA KOMITE NOMINASI DAN REMUNERASI"}{" "}
                  [PDF | 2.30 MB]
                </a>
              </div>
            </div>

            <div className="document-wrapper">
              <div className="document">
                <div className="icon">
                  <img src={icon} />
                </div>
                <a
                  href="/assets/media/LIKE _ Draft SK Pengangkatan Unit Audit Internal.pdf"
                  className="name fw-700"
                >
                  {lang == "en" ? "Audit Internal Unit" : "Unit Audit Internal"}{" "}
                  [PDF | 537 KB]
                </a>
              </div>
            </div>
          </div>
          <div className={`${style["column-right"]} col-md-6`}>
            {committees.map((committee, index) => (
              <div key={index}>
                <h5 className={`${style.subtitle} fs-18 fw-400 BakbakOne`}>
                  {committee.category}
                </h5>
                <div className={style["committee-members"]}>
                  {committee.members.map((member) => (
                    <div className={style["committee-member"]} key={member.id}>
                      <label
                        className={`${style["committee-name"]} Exo2 fw-700 fs-24`}
                      >
                        {member.name}
                      </label>
                      <label className="styled-title dark fs-14">
                        {member.position}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="">
          <h3 className={`${style.title} Exo2 fs-36`}>
            <span className={style["highlight"]}>General</span>Meeting
            Shareholders
          </h3>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                target="_blank"
                href="/uploads/08.14.23_PPT RUPST FUTR 2023_FIX_UPDATE.pdf"
                className="name fw-700"
              >
                Materials of RUPS
              </a>
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "40px" }}>
          <h3 className={`${style.title} Exo2 fs-36`}>
            <span className={style["highlight"]}>Annual</span>Report
          </h3>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                href="/uploads/Annual Report FUTR (14mb).pdf"
                className="name fw-700"
                target="_blank"
              >
                Annual Report 2022 (FUTR)
              </a>
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "40px" }}>
          <h3 className={`${style.title} Exo2 fs-36`}>
            <span className={style["highlight"]}>Financial</span>Report
          </h3>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                href="/uploads/Final_Report_FS_1Q_FUTR_31_Maret_2023.pdf"
                className="name fw-700"
                target="_blank"
              >
                Financial Report 2023 Q1 (FUTR)
              </a>
            </div>
          </div>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                href="/uploads/Final FS 2Q FUTR 30 Juni 2023 (Send).pdf"
                className="name fw-700"
                target="_blank"
              >
                Financial Report 2023 Q2 (FUTR)
              </a>
            </div>
          </div>
        </div>
        <div className="" style={{ marginTop: "40px" }}>
          <h3 className={`${style.title} Exo2 fs-36`}>
            <span className={style["highlight"]}>Investor</span>Relation
          </h3>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                href="/uploads/FUTR _ Pengumuman RUPST tahun buku 2022 _ Billingual.pdf"
                className="name fw-700"
                target="_blank"
              >
                {lang === "en"
                  ? "Announcement of the 2022 AGM of FUTR"
                  : "Pengumuman RUPST tahun buku 2022"}
              </a>
            </div>
          </div>
          <div className="document-wrapper">
            <div className="document">
              <div className="icon">
                <img src={icon} />
              </div>
              <a
                href="/uploads/FUTR _ Pemanggilan RUPST tahun buku 2022 PT LIKE.pdf"
                className="name fw-700"
                target="_blank"
              >
                {lang === "en"
                  ? "Convocation of the 2022 Annual General Meeting Of Shareholders PT LIKE TBK"
                  : "Pemanggilan RUPST tahun buku 2022 PT LIKE TBK"}
              </a>
            </div>
          </div>
        </div>
      </div>
      <img src={decorator} className={style.decorator} />
    </div>
  );
}
