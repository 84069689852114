import AppContext from "AppContext";
import logo from "assets/images/icon_futurphuture.png";
import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import style from "./History.module.css";
import { data_en, data_id } from "./data";

export default function History() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/history?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className="bgc-darkgrey pos-rel">
      {data && <div className="container">
        <div className={`${style.content}`}>
          <h3 className={`${style.title} fs-36 Exo2`}>
            <span>
              {data.history_unhighlighted_title}
            </span>
            <span className={style["highlight"]}>
              {data.history_highlighted_title}
            </span>
          </h3>
          <div className="col-md-6">
            <div className={`${style.description}`}>
              <ReactMarkdown
                transformImageUri={uri => uri.startsWith("http") ? uri : `${baseUrl}${uri}`}
              >
                {data.history_description}
              </ReactMarkdown>
            </div>
          </div>
          <h3
            className={`${style.title} fs-36 Exo2`}
            style={{ marginTop: "1.5em" }}
          >
            <span>
              {data.history_unhighlighted_title}
            </span>
            <span className={style["highlight"]}>
              {data.today_highlighted_title}
            </span>
          </h3>
          <div className={style.description}>
            <ReactMarkdown
              transformImageUri={uri => uri.startsWith("http") ? uri : `${baseUrl}${uri}`}
            >
              {data.today_description}
            </ReactMarkdown>
          </div>
        </div>
        <img src={logo} className={style["bg-logo"]} />
      </div>}
    </div>
  );
}
