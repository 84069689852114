export const data_en = {
  "id":1,
  "highlighted_title":"STRATEGIC",
  "unhighlighted_title":"PARTNERSHIP",
  "locale":"en",
  "published_at":"2023-02-10T06:19:19.890Z",
  "created_at":"2023-02-10T06:19:17.249Z",
  "updated_at":"2023-02-14T11:32:08.981Z",
  "partnerships":[
  {
  "id":29,
  "label":"Adventure Reality",
  "value":"Is a unique O2O ecosystem built on Solana chain in the form of a Metaverse that powers business driven gamification",
  "media":{
  "id":53,
  "name":"logo_adventure_reality.png",
  "alternativeText":"",
  "caption":"",
  "width":128,
  "height":35,
  "formats":null,
  "hash":"logo_adventure_reality_25ca8c19ae",
  "ext":".png",
  "mime":"image/png",
  "size":2.54,
  "url":"/uploads/logo_adventure_reality_25ca8c19ae.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:13:56.813Z",
  "updated_at":"2023-02-10T06:13:56.823Z"
  }
  },
  {
  "id":30,
  "label":"Framemotion",
  "value":"A pioneer in generating immersive application, content and experiences since 2008, FRAMEMOTION STUDIO’s team of 100+ designers, engineers, CG animators, Unity and Unreal developers remain at the forefront of tech advancement and innovation; in pursuance of crafting unique human-tech encounters through AR, VR and XR solutions.",
  "media":{
  "id":55,
  "name":"logo_framemotion.png",
  "alternativeText":"",
  "caption":"",
  "width":150,
  "height":63,
  "formats":null,
  "hash":"logo_framemotion_dd3d916391",
  "ext":".png",
  "mime":"image/png",
  "size":1.79,
  "url":"/uploads/logo_framemotion_dd3d916391.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:16:41.432Z",
  "updated_at":"2023-02-10T06:16:41.441Z"
  }
  },
  {
  "id":31,
  "label":"Future Wave",
  "value":"An independent managed programmatic ad service equipped with hyper-targeting capabilities, cross-channel attribution and on-time cohort enrichment.\n  \n  \nWith its proprietary technology; data analytics and custom methodologies to serve a myriad of marketing goals, our clients can expect performance-driven campaigns with great Return on Ad Spend.",
  "media":{
  "id":54,
  "name":"logo_future_wave.png",
  "alternativeText":"",
  "caption":"",
  "width":137,
  "height":37,
  "formats":null,
  "hash":"logo_future_wave_2bc115fa95",
  "ext":".png",
  "mime":"image/png",
  "size":4.26,
  "url":"/uploads/logo_future_wave_2bc115fa95.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:15:20.103Z",
  "updated_at":"2023-02-10T06:15:20.112Z"
  }
  },
  {
  "id":32,
  "label":"Group-IB",
  "value":"A decorated global agency; a trusted partner of INTERPOL, Europol, specialises in attribution-based Threat Intelligence, best-in-class Threat Hunting and Digital Risk Protection with clients across 60+ countries.",
  "media":{
  "id":56,
  "name":"logo_group_ib.png",
  "alternativeText":"",
  "caption":"",
  "width":150,
  "height":27,
  "formats":null,
  "hash":"logo_group_ib_4f2449229e",
  "ext":".png",
  "mime":"image/png",
  "size":1.18,
  "url":"/uploads/logo_group_ib_4f2449229e.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:18:00.512Z",
  "updated_at":"2023-02-10T06:18:00.523Z"
  }
  },
  {
  "id":33,
  "label":"Skyflow",
  "value":"This built to solve the world’s ever-growing data privacy problem by delivering Data Privacy Vaults via a simple API, so that every app, system, and workflow can be provided of best-in-class data privacy.",
  "media":{
  "id":52,
  "name":"logo_skyflow.png",
  "alternativeText":"",
  "caption":"",
  "width":151,
  "height":46,
  "formats":null,
  "hash":"logo_skyflow_1a02948ed6",
  "ext":".png",
  "mime":"image/png",
  "size":1.45,
  "url":"/uploads/logo_skyflow_1a02948ed6.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:12:34.944Z",
  "updated_at":"2023-02-10T06:12:34.954Z"
  }
  }
  ],
  "localizations":[
  {
  "id":2,
  "locale":"id",
  "published_at":"2023-02-13T08:02:39.068Z"
  }
  ]
}

export const data_id = {
  "id":2,
  "highlighted_title":"STRATEGIC",
  "unhighlighted_title":"PARTNERSHIP",
  "locale":"id",
  "published_at":"2023-02-13T08:02:39.068Z",
  "created_at":"2023-02-13T08:02:37.593Z",
  "updated_at":"2023-02-14T11:45:13.138Z",
  "partnerships":[
  {
  "id":34,
  "label":"Adventure Reality",
  "value":"Adalah ekosistem unik O2O yang dibangun di *chain* Solana dalam bentuk *Metaverse* yang mendukung gamifikasi berbasis bisnis.",
  "media":{
  "id":53,
  "name":"logo_adventure_reality.png",
  "alternativeText":"",
  "caption":"",
  "width":128,
  "height":35,
  "formats":null,
  "hash":"logo_adventure_reality_25ca8c19ae",
  "ext":".png",
  "mime":"image/png",
  "size":2.54,
  "url":"/uploads/logo_adventure_reality_25ca8c19ae.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:13:56.813Z",
  "updated_at":"2023-02-10T06:13:56.823Z"
  }
  },
  {
  "id":14,
  "label":"Framemotion",
  "value":"Pelopor dalam menghasilkan aplikasi, konten, dan pengalaman imersif sejak 2008, tim *FRAMEMOTION STUDIO* yang terdiri dari 100+ desainer, *engineer*, *animator CG*, pengembang Unity dan Unreal tetap berada di paling depan dalam kemajuan dan inovasi teknologi; untuk menyusun penggabungan manusia dengan teknologi secara *real-time* yang unik melalui solusi *AR*, *VR*, dan *XR*.",
  "media":{
  "id":55,
  "name":"logo_framemotion.png",
  "alternativeText":"",
  "caption":"",
  "width":150,
  "height":63,
  "formats":null,
  "hash":"logo_framemotion_dd3d916391",
  "ext":".png",
  "mime":"image/png",
  "size":1.79,
  "url":"/uploads/logo_framemotion_dd3d916391.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:16:41.432Z",
  "updated_at":"2023-02-10T06:16:41.441Z"
  }
  },
  {
  "id":13,
  "label":"Future Wave",
  "value":"Perusahaan independen yang mengelola layanan iklan *programmatic* dilengkapi dengan kemampuan *hyper-targeting*, mengatribusi melalui saluran, dan memastikan tujuan dengan tepat waktu.\n  \n  \nDengan teknologi yang dimiliki, analisa data dan metodologi khusus untuk melayani berbagai tujuan pemasaran, klien kami dapat mengharapkan kampanye yang didorong oleh kinerja dengan tingkat pengembalian investasi yang besar pada pembelanjaan iklan.",
  "media":{
  "id":54,
  "name":"logo_future_wave.png",
  "alternativeText":"",
  "caption":"",
  "width":137,
  "height":37,
  "formats":null,
  "hash":"logo_future_wave_2bc115fa95",
  "ext":".png",
  "mime":"image/png",
  "size":4.26,
  "url":"/uploads/logo_future_wave_2bc115fa95.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:15:20.103Z",
  "updated_at":"2023-02-10T06:15:20.112Z"
  }
  },
  {
  "id":15,
  "label":"Group IB",
  "value":"Agensi global yang dipercayai oleh *INTERPOL*, *Europol*, yang memiliki spesialisasi atribusi yang berbasis *Threat Intelligence*, *best-in-class Threat Hunting* dan *Digital Risk Protection* dengan klien di 60+ negara.",
  "media":{
  "id":56,
  "name":"logo_group_ib.png",
  "alternativeText":"",
  "caption":"",
  "width":150,
  "height":27,
  "formats":null,
  "hash":"logo_group_ib_4f2449229e",
  "ext":".png",
  "mime":"image/png",
  "size":1.18,
  "url":"/uploads/logo_group_ib_4f2449229e.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:18:00.512Z",
  "updated_at":"2023-02-10T06:18:00.523Z"
  }
  },
  {
  "id":16,
  "label":"Skyflow",
  "value":"Dibuat untuk memecahkan masalah privasi data yang terus berkembang di dunia dengan menyediakan brankas privasi data melalui API sederhana, sehingga setiap aplikasi, sistem, dan alur kerja dapat memiliki privasi data terbaik di kelasnya.",
  "media":{
  "id":52,
  "name":"logo_skyflow.png",
  "alternativeText":"",
  "caption":"",
  "width":151,
  "height":46,
  "formats":null,
  "hash":"logo_skyflow_1a02948ed6",
  "ext":".png",
  "mime":"image/png",
  "size":1.45,
  "url":"/uploads/logo_skyflow_1a02948ed6.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-10T06:12:34.944Z",
  "updated_at":"2023-02-10T06:12:34.954Z"
  }
  }
  ],
  "localizations":[
  {
  "id":1,
  "locale":"en",
  "published_at":"2023-02-10T06:19:19.890Z"
  }
  ]
}