export const directors_en = [
  {
  "id":1,
  "name":"Jeremy Quek",
  "position":"Chief Executive Officer",
  "published_at":"2022-11-07T09:15:56.507Z",
  "created_at":"2022-11-07T09:15:54.477Z",
  "updated_at":"2022-11-09T04:21:52.674Z",
  "locale":"en",
  "profile_picture":{
  "id":24,
  "name":"pp_jeremy.png",
  "alternativeText":"",
  "caption":"",
  "width":352,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_jeremy_5574fe9b0c.png",
  "hash":"thumbnail_pp_jeremy_5574fe9b0c",
  "mime":"image/png",
  "name":"thumbnail_pp_jeremy.png",
  "path":null,
  "size":31.59,
  "width":173,
  "height":156
  }
  },
  "hash":"pp_jeremy_5574fe9b0c",
  "ext":".png",
  "mime":"image/png",
  "size":84.1,
  "url":"/uploads/pp_jeremy_5574fe9b0c.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.934Z",
  "updated_at":"2022-11-09T04:16:17.293Z"
  },
  "background_picture":{
  "id":31,
  "name":"pp_bg_1.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_1_f714e05808.png",
  "hash":"thumbnail_pp_bg_1_f714e05808",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_1.png",
  "path":null,
  "size":72.02,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_1_f714e05808",
  "ext":".png",
  "mime":"image/png",
  "size":219.03,
  "url":"/uploads/pp_bg_1_f714e05808.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.659Z",
  "updated_at":"2022-11-09T04:16:17.862Z"
  },
  "localizations":[
  {
  "id":5,
  "locale":"id",
  "published_at":"2022-11-09T04:22:03.958Z"
  }
  ]
  },
  {
  "id":2,
  "name":"Vicktor Aritonang",
  "position":"Chief Financial Officer",
  "published_at":"2022-11-07T09:18:27.946Z",
  "created_at":"2022-11-07T09:18:25.853Z",
  "updated_at":"2022-11-09T04:22:59.179Z",
  "locale":"en",
  "profile_picture":{
  "id":26,
  "name":"pp_vicktor.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_vicktor_78af134c67.png",
  "hash":"thumbnail_pp_vicktor_78af134c67",
  "mime":"image/png",
  "name":"thumbnail_pp_vicktor.png",
  "path":null,
  "size":34.84,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_vicktor_78af134c67",
  "ext":".png",
  "mime":"image/png",
  "size":87.7,
  "url":"/uploads/pp_vicktor_78af134c67.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.955Z",
  "updated_at":"2022-11-09T04:16:17.326Z"
  },
  "background_picture":{
  "id":33,
  "name":"pp_bg_2.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_2_25dd5fd20c.png",
  "hash":"thumbnail_pp_bg_2_25dd5fd20c",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_2.png",
  "path":null,
  "size":64.68,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_2_25dd5fd20c",
  "ext":".png",
  "mime":"image/png",
  "size":179.16,
  "url":"/uploads/pp_bg_2_25dd5fd20c.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.882Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":6,
  "locale":"id",
  "published_at":"2022-11-09T04:23:05.863Z"
  }
  ]
  },
  {
  "id":3,
  "name":"Nizar Kader",
  "position":"Chief Data Officer",
  "published_at":"2022-11-07T09:19:10.821Z",
  "created_at":"2022-11-07T09:19:08.620Z",
  "updated_at":"2022-11-09T11:14:47.452Z",
  "locale":"en",
  "profile_picture":{
  "id":40,
  "name":"pp_nizar.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_nizar_2e17360dbf.png",
  "hash":"thumbnail_pp_nizar_2e17360dbf",
  "mime":"image/png",
  "name":"thumbnail_pp_nizar.png",
  "path":null,
  "size":41.9,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_nizar_2e17360dbf",
  "ext":".png",
  "mime":"image/png",
  "size":100.83,
  "url":"/uploads/pp_nizar_2e17360dbf.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T11:14:13.907Z",
  "updated_at":"2022-11-09T11:14:13.922Z"
  },
  "background_picture":{
  "id":36,
  "name":"pp_bg_3.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_3_5db5242ff0.png",
  "hash":"thumbnail_pp_bg_3_5db5242ff0",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_3.png",
  "path":null,
  "size":78.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_3_5db5242ff0",
  "ext":".png",
  "mime":"image/png",
  "size":273.52,
  "url":"/uploads/pp_bg_3_5db5242ff0.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.898Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":7,
  "locale":"id",
  "published_at":"2022-11-09T04:24:33.652Z"
  }
  ]
  },
  {
  "id":4,
  "name":"Irfan Handoko",
  "position":"Head of Commercial",
  "published_at":"2022-11-07T09:21:37.756Z",
  "created_at":"2022-11-07T09:19:37.608Z",
  "updated_at":"2022-11-09T04:25:35.537Z",
  "locale":"en",
  "profile_picture":{
  "id":25,
  "name":"pp_irfan.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_irfan_616c9ae0fe.png",
  "hash":"thumbnail_pp_irfan_616c9ae0fe",
  "mime":"image/png",
  "name":"thumbnail_pp_irfan.png",
  "path":null,
  "size":35.38,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_irfan_616c9ae0fe",
  "ext":".png",
  "mime":"image/png",
  "size":91.55,
  "url":"/uploads/pp_irfan_616c9ae0fe.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.939Z",
  "updated_at":"2022-11-09T04:16:17.305Z"
  },
  "background_picture":{
  "id":34,
  "name":"pp_bg_4.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_4_27b5c60a78.png",
  "hash":"thumbnail_pp_bg_4_27b5c60a78",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_4.png",
  "path":null,
  "size":65.97,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_4_27b5c60a78",
  "ext":".png",
  "mime":"image/png",
  "size":191.18,
  "url":"/uploads/pp_bg_4_27b5c60a78.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.883Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":8,
  "locale":"id",
  "published_at":"2022-11-09T04:25:45.562Z"
  }
  ]
  },
  {
  "id":9,
  "name":"Albert S. Darmali",
  "position":"IT Director",
  "published_at":"2022-11-09T04:35:53.314Z",
  "created_at":"2022-11-09T04:26:20.409Z",
  "updated_at":"2022-11-09T04:35:53.362Z",
  "locale":"en",
  "profile_picture":{
  "id":23,
  "name":"pp_albert.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_albert_4c05862499.png",
  "hash":"thumbnail_pp_albert_4c05862499",
  "mime":"image/png",
  "name":"thumbnail_pp_albert.png",
  "path":null,
  "size":38.67,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_albert_4c05862499",
  "ext":".png",
  "mime":"image/png",
  "size":99.34,
  "url":"/uploads/pp_albert_4c05862499.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:15.562Z",
  "updated_at":"2022-11-09T04:16:15.578Z"
  },
  "background_picture":{
  "id":35,
  "name":"pp_bg_5.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_5_73a3736a91.png",
  "hash":"thumbnail_pp_bg_5_73a3736a91",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_5.png",
  "path":null,
  "size":77.14,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_5_73a3736a91",
  "ext":".png",
  "mime":"image/png",
  "size":244.75,
  "url":"/uploads/pp_bg_5_73a3736a91.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.883Z",
  "updated_at":"2022-11-10T04:34:13.997Z"
  },
  "localizations":[
  {
  "id":10,
  "locale":"id",
  "published_at":"2022-11-09T04:26:29.700Z"
  }
  ]
  }
];

export const directors_id = [
  {
  "id":5,
  "name":"Jeremy Quek",
  "position":"Direktur Utama",
  "published_at":"2022-11-09T04:22:03.958Z",
  "created_at":"2022-11-09T04:22:01.549Z",
  "updated_at":"2022-11-09T04:22:04.035Z",
  "locale":"id",
  "profile_picture":{
  "id":24,
  "name":"pp_jeremy.png",
  "alternativeText":"",
  "caption":"",
  "width":352,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_jeremy_5574fe9b0c.png",
  "hash":"thumbnail_pp_jeremy_5574fe9b0c",
  "mime":"image/png",
  "name":"thumbnail_pp_jeremy.png",
  "path":null,
  "size":31.59,
  "width":173,
  "height":156
  }
  },
  "hash":"pp_jeremy_5574fe9b0c",
  "ext":".png",
  "mime":"image/png",
  "size":84.1,
  "url":"/uploads/pp_jeremy_5574fe9b0c.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.934Z",
  "updated_at":"2022-11-09T04:16:17.293Z"
  },
  "background_picture":{
  "id":31,
  "name":"pp_bg_1.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_1_f714e05808.png",
  "hash":"thumbnail_pp_bg_1_f714e05808",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_1.png",
  "path":null,
  "size":72.02,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_1_f714e05808",
  "ext":".png",
  "mime":"image/png",
  "size":219.03,
  "url":"/uploads/pp_bg_1_f714e05808.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.659Z",
  "updated_at":"2022-11-09T04:16:17.862Z"
  },
  "localizations":[
  {
  "id":1,
  "locale":"en",
  "published_at":"2022-11-07T09:15:56.507Z"
  }
  ]
  },
  {
  "id":6,
  "name":"Vicktor Aritonang",
  "position":"Direktur Keuangan",
  "published_at":"2022-11-09T04:23:05.863Z",
  "created_at":"2022-11-09T04:23:04.161Z",
  "updated_at":"2022-11-09T04:23:05.967Z",
  "locale":"id",
  "profile_picture":{
  "id":26,
  "name":"pp_vicktor.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_vicktor_78af134c67.png",
  "hash":"thumbnail_pp_vicktor_78af134c67",
  "mime":"image/png",
  "name":"thumbnail_pp_vicktor.png",
  "path":null,
  "size":34.84,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_vicktor_78af134c67",
  "ext":".png",
  "mime":"image/png",
  "size":87.7,
  "url":"/uploads/pp_vicktor_78af134c67.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.955Z",
  "updated_at":"2022-11-09T04:16:17.326Z"
  },
  "background_picture":{
  "id":33,
  "name":"pp_bg_2.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_2_25dd5fd20c.png",
  "hash":"thumbnail_pp_bg_2_25dd5fd20c",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_2.png",
  "path":null,
  "size":64.68,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_2_25dd5fd20c",
  "ext":".png",
  "mime":"image/png",
  "size":179.16,
  "url":"/uploads/pp_bg_2_25dd5fd20c.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.882Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":2,
  "locale":"en",
  "published_at":"2022-11-07T09:18:27.946Z"
  }
  ]
  },
  {
  "id":7,
  "name":"Nizar Kader",
  "position":"Direktur",
  "published_at":"2022-11-09T04:24:33.652Z",
  "created_at":"2022-11-09T04:24:31.804Z",
  "updated_at":"2022-11-09T04:24:33.689Z",
  "locale":"id",
  "profile_picture":{
  "id":40,
  "name":"pp_nizar.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_nizar_2e17360dbf.png",
  "hash":"thumbnail_pp_nizar_2e17360dbf",
  "mime":"image/png",
  "name":"thumbnail_pp_nizar.png",
  "path":null,
  "size":41.9,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_nizar_2e17360dbf",
  "ext":".png",
  "mime":"image/png",
  "size":100.83,
  "url":"/uploads/pp_nizar_2e17360dbf.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T11:14:13.907Z",
  "updated_at":"2022-11-09T11:14:13.922Z"
  },
  "background_picture":{
  "id":36,
  "name":"pp_bg_3.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_3_5db5242ff0.png",
  "hash":"thumbnail_pp_bg_3_5db5242ff0",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_3.png",
  "path":null,
  "size":78.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_3_5db5242ff0",
  "ext":".png",
  "mime":"image/png",
  "size":273.52,
  "url":"/uploads/pp_bg_3_5db5242ff0.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.898Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":3,
  "locale":"en",
  "published_at":"2022-11-07T09:19:10.821Z"
  }
  ]
  },
  {
  "id":8,
  "name":"Irfan Handoko",
  "position":"Direktur",
  "published_at":"2022-11-09T04:25:45.562Z",
  "created_at":"2022-11-09T04:25:43.635Z",
  "updated_at":"2022-11-09T04:25:45.606Z",
  "locale":"id",
  "profile_picture":{
  "id":25,
  "name":"pp_irfan.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_irfan_616c9ae0fe.png",
  "hash":"thumbnail_pp_irfan_616c9ae0fe",
  "mime":"image/png",
  "name":"thumbnail_pp_irfan.png",
  "path":null,
  "size":35.38,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_irfan_616c9ae0fe",
  "ext":".png",
  "mime":"image/png",
  "size":91.55,
  "url":"/uploads/pp_irfan_616c9ae0fe.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.939Z",
  "updated_at":"2022-11-09T04:16:17.305Z"
  },
  "background_picture":{
  "id":34,
  "name":"pp_bg_4.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_4_27b5c60a78.png",
  "hash":"thumbnail_pp_bg_4_27b5c60a78",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_4.png",
  "path":null,
  "size":65.97,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_4_27b5c60a78",
  "ext":".png",
  "mime":"image/png",
  "size":191.18,
  "url":"/uploads/pp_bg_4_27b5c60a78.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.883Z",
  "updated_at":"2022-11-09T04:16:17.954Z"
  },
  "localizations":[
  {
  "id":4,
  "locale":"en",
  "published_at":"2022-11-07T09:21:37.756Z"
  }
  ]
  },
  {
  "id":10,
  "name":"Albert S. Darmali",
  "position":"Direktur",
  "published_at":"2022-11-09T04:26:29.700Z",
  "created_at":"2022-11-09T04:26:27.976Z",
  "updated_at":"2022-11-09T04:26:29.749Z",
  "locale":"id",
  "profile_picture":{
  "id":23,
  "name":"pp_albert.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_albert_4c05862499.png",
  "hash":"thumbnail_pp_albert_4c05862499",
  "mime":"image/png",
  "name":"thumbnail_pp_albert.png",
  "path":null,
  "size":38.67,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_albert_4c05862499",
  "ext":".png",
  "mime":"image/png",
  "size":99.34,
  "url":"/uploads/pp_albert_4c05862499.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:15.562Z",
  "updated_at":"2022-11-09T04:16:15.578Z"
  },
  "background_picture":{
  "id":35,
  "name":"pp_bg_5.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_5_73a3736a91.png",
  "hash":"thumbnail_pp_bg_5_73a3736a91",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_5.png",
  "path":null,
  "size":77.14,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_5_73a3736a91",
  "ext":".png",
  "mime":"image/png",
  "size":244.75,
  "url":"/uploads/pp_bg_5_73a3736a91.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.883Z",
  "updated_at":"2022-11-10T04:34:13.997Z"
  },
  "localizations":[
  {
  "id":9,
  "locale":"en",
  "published_at":"2022-11-09T04:35:53.314Z"
  }
  ]
  }
]

export const commissioners_en = [
  {
  "id":1,
  "name":"Jeffrey Wangsawidjaja",
  "position":"Chief Commissioner",
  "published_at":"2022-11-07T09:20:27.832Z",
  "created_at":"2022-11-07T09:20:25.275Z",
  "updated_at":"2022-11-09T04:27:04.092Z",
  "locale":"en",
  "profile_picture":{
  "id":28,
  "name":"pp_jeffrey.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_jeffrey_8ad272ac94.png",
  "hash":"thumbnail_pp_jeffrey_8ad272ac94",
  "mime":"image/png",
  "name":"thumbnail_pp_jeffrey.png",
  "path":null,
  "size":34.53,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_jeffrey_8ad272ac94",
  "ext":".png",
  "mime":"image/png",
  "size":85.29,
  "url":"/uploads/pp_jeffrey_8ad272ac94.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.043Z",
  "updated_at":"2022-11-09T04:16:17.407Z"
  },
  "background_picture":{
  "id":38,
  "name":"pp_bg_6.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_6_2b699d1969.png",
  "hash":"thumbnail_pp_bg_6_2b699d1969",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_6.png",
  "path":null,
  "size":72.02,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_6_2b699d1969",
  "ext":".png",
  "mime":"image/png",
  "size":219.03,
  "url":"/uploads/pp_bg_6_2b699d1969.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.899Z",
  "updated_at":"2022-11-09T04:16:17.957Z"
  },
  "localizations":[
  {
  "id":4,
  "locale":"id",
  "published_at":"2022-11-09T06:38:43.085Z"
  }
  ]
  },
  {
  "id":2,
  "name":"Rian Saputra",
  "position":"Commissioner",
  "published_at":"2022-11-07T09:21:32.418Z",
  "created_at":"2022-11-07T09:20:48.280Z",
  "updated_at":"2022-11-09T04:27:45.221Z",
  "locale":"en",
  "profile_picture":{
  "id":29,
  "name":"pp_rian.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_rian_c01bf8cb3e.png",
  "hash":"thumbnail_pp_rian_c01bf8cb3e",
  "mime":"image/png",
  "name":"thumbnail_pp_rian.png",
  "path":null,
  "size":36.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_rian_c01bf8cb3e",
  "ext":".png",
  "mime":"image/png",
  "size":90.79,
  "url":"/uploads/pp_rian_c01bf8cb3e.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.157Z",
  "updated_at":"2022-11-09T04:16:17.484Z"
  },
  "background_picture":{
  "id":32,
  "name":"pp_bg_7.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_7_5c72e76deb.png",
  "hash":"thumbnail_pp_bg_7_5c72e76deb",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_7.png",
  "path":null,
  "size":64.68,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_7_5c72e76deb",
  "ext":".png",
  "mime":"image/png",
  "size":179.16,
  "url":"/uploads/pp_bg_7_5c72e76deb.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.808Z",
  "updated_at":"2022-11-09T04:16:17.869Z"
  },
  "localizations":[
  {
  "id":5,
  "locale":"id",
  "published_at":"2022-11-09T04:27:54.014Z"
  }
  ]
  },
  {
  "id":3,
  "name":"Nathaniel Kwai",
  "position":"Independent Commissioner",
  "published_at":"2022-11-07T09:21:29.370Z",
  "created_at":"2022-11-07T09:21:19.863Z",
  "updated_at":"2022-11-09T04:28:24.406Z",
  "locale":"en",
  "profile_picture":{
  "id":27,
  "name":"pp_nathan.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_nathan_54b5195c05.png",
  "hash":"thumbnail_pp_nathan_54b5195c05",
  "mime":"image/png",
  "name":"thumbnail_pp_nathan.png",
  "path":null,
  "size":38.22,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_nathan_54b5195c05",
  "ext":".png",
  "mime":"image/png",
  "size":100.04,
  "url":"/uploads/pp_nathan_54b5195c05.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.956Z",
  "updated_at":"2022-11-09T04:16:17.332Z"
  },
  "background_picture":{
  "id":37,
  "name":"pp_bg_8.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_8_7859612ac8.png",
  "hash":"thumbnail_pp_bg_8_7859612ac8",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_8.png",
  "path":null,
  "size":78.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_8_7859612ac8",
  "ext":".png",
  "mime":"image/png",
  "size":273.52,
  "url":"/uploads/pp_bg_8_7859612ac8.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.899Z",
  "updated_at":"2022-11-09T04:16:17.957Z"
  },
  "localizations":[
  {
  "id":6,
  "locale":"id",
  "published_at":"2022-11-09T04:28:36.237Z"
  }
  ]
  }
];

export const commissioners_id = [
  {
  "id":4,
  "name":"Jeffrey Wangsawidjaja",
  "position":"Komisaris Utama",
  "published_at":"2022-11-09T06:38:43.085Z",
  "created_at":"2022-11-09T04:27:12.426Z",
  "updated_at":"2022-11-09T06:38:43.125Z",
  "locale":"id",
  "profile_picture":{
  "id":28,
  "name":"pp_jeffrey.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_jeffrey_8ad272ac94.png",
  "hash":"thumbnail_pp_jeffrey_8ad272ac94",
  "mime":"image/png",
  "name":"thumbnail_pp_jeffrey.png",
  "path":null,
  "size":34.53,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_jeffrey_8ad272ac94",
  "ext":".png",
  "mime":"image/png",
  "size":85.29,
  "url":"/uploads/pp_jeffrey_8ad272ac94.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.043Z",
  "updated_at":"2022-11-09T04:16:17.407Z"
  },
  "background_picture":{
  "id":38,
  "name":"pp_bg_6.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_6_2b699d1969.png",
  "hash":"thumbnail_pp_bg_6_2b699d1969",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_6.png",
  "path":null,
  "size":72.02,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_6_2b699d1969",
  "ext":".png",
  "mime":"image/png",
  "size":219.03,
  "url":"/uploads/pp_bg_6_2b699d1969.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.899Z",
  "updated_at":"2022-11-09T04:16:17.957Z"
  },
  "localizations":[
  {
  "id":1,
  "locale":"en",
  "published_at":"2022-11-07T09:20:27.832Z"
  }
  ]
  },
  {
  "id":5,
  "name":"Rian Saputra",
  "position":"Komisaris",
  "published_at":"2022-11-09T04:27:54.014Z",
  "created_at":"2022-11-09T04:27:52.035Z",
  "updated_at":"2022-11-09T04:27:54.074Z",
  "locale":"id",
  "profile_picture":{
  "id":29,
  "name":"pp_rian.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_rian_c01bf8cb3e.png",
  "hash":"thumbnail_pp_rian_c01bf8cb3e",
  "mime":"image/png",
  "name":"thumbnail_pp_rian.png",
  "path":null,
  "size":36.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_rian_c01bf8cb3e",
  "ext":".png",
  "mime":"image/png",
  "size":90.79,
  "url":"/uploads/pp_rian_c01bf8cb3e.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.157Z",
  "updated_at":"2022-11-09T04:16:17.484Z"
  },
  "background_picture":{
  "id":32,
  "name":"pp_bg_7.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_7_5c72e76deb.png",
  "hash":"thumbnail_pp_bg_7_5c72e76deb",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_7.png",
  "path":null,
  "size":64.68,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_7_5c72e76deb",
  "ext":".png",
  "mime":"image/png",
  "size":179.16,
  "url":"/uploads/pp_bg_7_5c72e76deb.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.808Z",
  "updated_at":"2022-11-09T04:16:17.869Z"
  },
  "localizations":[
  {
  "id":2,
  "locale":"en",
  "published_at":"2022-11-07T09:21:32.418Z"
  }
  ]
  },
  {
  "id":6,
  "name":"Nathaniel Kwai",
  "position":"Komisaris Independen",
  "published_at":"2022-11-09T04:28:36.237Z",
  "created_at":"2022-11-09T04:28:33.131Z",
  "updated_at":"2022-11-09T04:28:36.277Z",
  "locale":"id",
  "profile_picture":{
  "id":27,
  "name":"pp_nathan.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_nathan_54b5195c05.png",
  "hash":"thumbnail_pp_nathan_54b5195c05",
  "mime":"image/png",
  "name":"thumbnail_pp_nathan.png",
  "path":null,
  "size":38.22,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_nathan_54b5195c05",
  "ext":".png",
  "mime":"image/png",
  "size":100.04,
  "url":"/uploads/pp_nathan_54b5195c05.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:16.956Z",
  "updated_at":"2022-11-09T04:16:17.332Z"
  },
  "background_picture":{
  "id":37,
  "name":"pp_bg_8.png",
  "alternativeText":"",
  "caption":"",
  "width":356,
  "height":318,
  "formats":{
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_pp_bg_8_7859612ac8.png",
  "hash":"thumbnail_pp_bg_8_7859612ac8",
  "mime":"image/png",
  "name":"thumbnail_pp_bg_8.png",
  "path":null,
  "size":78.44,
  "width":175,
  "height":156
  }
  },
  "hash":"pp_bg_8_7859612ac8",
  "ext":".png",
  "mime":"image/png",
  "size":273.52,
  "url":"/uploads/pp_bg_8_7859612ac8.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2022-11-09T04:16:17.899Z",
  "updated_at":"2022-11-09T04:16:17.957Z"
  },
  "localizations":[
  {
  "id":3,
  "locale":"en",
  "published_at":"2022-11-07T09:21:29.370Z"
  }
  ]
  }
];