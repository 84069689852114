import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import style from "./Modal.module.css";

export default function Modal({show, onClose, isBlank=false, children}) {
  useEffect(() => {
    const closeOnEscapeKey = (e) => e.key === "Escape" ? onClose() : null;
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  return <>
    {show && <>
      <div className={style["modal-backdrop"]}></div>
      <div className={style["modal-container"]}>
        { isBlank ? children :
          <div className={style["modal"]}>
            <FaTimes className={style["btn-close"]} onClick={() => onClose()}></FaTimes>
            {children}
          </div>
        }
      </div>
    </>}
  </>
}