import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import style from "./KnowMore.module.css";
import { data_en, data_id } from "./data";

export default function KnowMore() {
  const { baseUrl, lang } = useContext(AppContext);
  const [knowmore, setKnowmore] = useState({});

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/know-more?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setKnowmore(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={`${style["section-container"]} bgc-yellow smooth-transition`}>
      <div className={style.section}>
        <div className={style["section-content"]}>
          <div className={style["section-content-inner"]}>
            <h3
              className="BakbakOne text-center"
              style={{ marginBottom: "0.5em" }}
            >
              {knowmore.title}
            </h3>
            {/* <div className="smooth-transition text-center">
              <img src="/assets/images/icon_chevronright_purple.png" />
            </div> */}
            <div className={style["section-description"]}>
              <ReactMarkdown>{knowmore.content}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// import { useContext, useEffect, useRef, useState } from "react";
// import ReactMarkdown from "react-markdown";
// import AppContext from "../AppContext";
// import { useWindowSize } from "../utilities/utilities";

// export default function KnowMore() {
//   const {baseUrl} = useContext(AppContext);
//   const [knowmore, setKnowmore] = useState({});
//   const [windowWidth, windowHeight] = useWindowSize();

//   const [isHovered, setIsHovered] = useState(false);
//   const [titleStyle, setTitleStyle] = useState({});
//   const [descriptionStyle, setDescriptionStyle] = useState({});
//   const descriptionRef = useRef(null);

//   const fetchData = async () => {
//     const fetchResult = await fetch(`${baseUrl}/know-more`);
//     const result = await fetchResult.json();
//     setKnowmore(result);
//   }

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     if (isHovered) {
//       setTitleStyle({transform: "translateY(-100%)"});
//       setDescriptionStyle({transform: "translateY(-100%)"});
//     } else {
//       if (windowWidth >= 768) {
//         setTitleStyle({transform: `translateY(0)`});
//         setDescriptionStyle({transform: "translateY(0)"});
//       }
//     }
//   }, [windowWidth, isHovered, knowmore]);

//   return (
//     <div className="flex-item section-knowmore section-background animated-background col-md-6"
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       <div className="section-container">
//         <div className="section-content align-items-center justify-content-center">
//           <div className="section-content-inner">
//             <div className="section-container align-items-center justify-content-center smooth-transition" style={titleStyle}>
//               <h3 className="fw-700 text-center " >
//                 WANNA<br/>KNOW MORE?
//               </h3>
//               <div className="smooth-transition text-center">
//                 <img src="/assets/images/icon_chevronright_purple.png" />
//               </div>
//             </div>
//             <div className="description-container" style={descriptionStyle}>
//               <div className="section-description" ref={descriptionRef} >
//                 <ReactMarkdown>
//                   {knowmore.content}
//                 </ReactMarkdown>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
