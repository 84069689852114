import AppContext from "AppContext";
import style from "./Newsroom.module.css";
import news1 from "assets/images/news_1.png";
import news2 from "assets/images/news_2.png";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getListHighlightNews } from "services/Api/News/News";
import "moment/locale/id";
import moment from "moment";
import {
  ArrowRight,
  CaretRightFill,
  ChevronDoubleRight,
} from "react-bootstrap-icons";

export default function Newsroom() {
  const language = useContext(AppContext);

  const posts = [
    {
      title: "Siap IPO, Lini Imaji Kreasi (FUTR) Incar Dana Segar Rp153 Miliar",
      date: "31.01.2023",
      type: "Article",
      image: news1,
      link: "https://www.idxchannel.com/market-news/siap-ipo-lini-imaji-kreasi-futr-incar-dana-segar-rp153-miliar",
    },
    {
      title:
        "Rambah Pasar Hongkong, Lini Imaji Kreasi Incar Rp153 Miliar Dari IPO",
      date: "31.01.2023",
      type: "Article",
      image: news2,
      link: "https://pasardana.id/news/2023/1/31/rambah-pasar-hongkong-lini-imaji-kreasi-incar-rp153-miliar-dari-ipo/",
    },
  ];

  const [postNews, setPostNews] = useState();

  useEffect(() => {
    getHighlightNews();
  }, []);

  async function getHighlightNews() {
    console.log("masuk pak eko");
    getListHighlightNews()
      .then((res) => {
        console.log(res);
        if (res.data.highlight.length >= 2) {
          const temp_data = res.data.highlight.slice(0, 2);
          setPostNews(temp_data);
        } else {
          let temp_data = [];

          if (res.data.highlight.length === 0) {
            temp_data = res.data.data.slice(0, 2);
            setPostNews(temp_data);
          } else {
            temp_data = [
              ...temp_data,
              res.data.highlight[0],
              res.data.data.slice(0, 1)[0],
            ];
            setPostNews(temp_data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleClick() {
    // window.location.href = "https://blog.futr.asia/";
    window.open("https://blog.futr.asia/", "_blank");
  }

  console.log(postNews);

  return (
    <div className={style["container"]}>
      <h3 className={`BakbakOne ${style["title"]}`}>
        <span>Newsroom</span>
      </h3>
      <div className={style["news-container"]}>
        {postNews &&
          postNews.map((post, index) => (
            <a
              href={`https://blog.futr.asia/detail/${post.uuid}`}
              className={style["news"]}
              target="_blank"
              key={index}
            >
              <img
                src={post.url_photo}
                alt={post.title}
                className={style["news-image"]}
              />

              <div className={style["news-content"]}>
                <h4 className={style["news-title"]}>
                  {language.lang === "id"
                    ? JSON.parse(post.title).id
                    : JSON.parse(post.title).en}
                </h4>
                <p>{moment(post.created_at).format("LL")}</p>
              </div>
            </a>
          ))}
      </div>
      <div className={style["read-all"]} onClick={handleClick}>
        {/* <p className={style["read-all-text"]}>
          <strong>Read all post</strong>
        </p> */}
        <h3 className={`BakbakOne ${style["read-all-text"]}`}>
          Read all post &gt;
        </h3>
      </div>
      {/* <div className={style["readmore-container"]}>
        <Link to={"/"}>
          Read all post
        </Link>
      </div> */}
    </div>
  );
}
