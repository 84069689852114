import style from "./Expansion.module.css";
import map from "assets/images/asia_pacific_expansion.png";

export default function Expansion() {
  return (
    <>
      <h3 className={`BakbakOne ${style["title"]}`}>
        <span className={style["highlight"]}>ASIA PACIFIC EXPANSION</span>
        <span>WITHIN THE NEXT 24 MONTHS</span>
      </h3>
      <img className={style["map"]} src={map} alt="Asia Pacific Expansion" />
    </>
  )
}