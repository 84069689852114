import KnowMore from "./KnowMore/KnowMore";
import Stock from "./Stock/Stock";
import style from "./StockGroup.module.css";
import TheOne from "./TheOne/TheOne";

export default function StockGroup() {
  return (
    <div className={style.container}>
      {/* <Stock /> */}
      <TheOne />
      <KnowMore />
    </div>
  )
}