import { useEffect } from "react";
import Committee from "./components/Committee/Committee";
import CorporateSecretary from "./components/CorporateSecretary/CorporateSecretary";
import Expansion from "./components/Expansion/Expansion";
import History from "./components/History/History";
import Management from "./components/Management/Management";
import VisionMission from "./components/VisionMission/VisionMission";

export default function GoodCorporateGovernance() {
  useEffect(() => {
    document.title = "Good Corporate Governance | FUTURPHUTURE - PT Lini Imaji Kreasi Ekosistem"
  }, []);

  return (
    <>
      <VisionMission />
      <History />
      <Expansion />
      <Management />
      <Committee />
      <CorporateSecretary />
    </>
  )
}