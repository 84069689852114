import Contact from "./Contact/Contact";
import HQ from "./HQ/HQ";
import image from "assets/images/footer_line.png";
import FooterLinks from "./FooterLinks/FooterLinks";
import style from "./Footer.module.css";

export default function Footer() {
  return (
    <>
      <div className="container">
        {/* <div className="row"> */}
        <div className={style["content-top-container"]}>
          <Contact />
          <HQ />
        </div>
        {/* </div> */}
      </div>
      <img src={image} className="w-100" />
      <FooterLinks />
    </>
  );
}
