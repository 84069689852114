import style from "./WhatWeDo.module.css";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import Modal from "shared/components/Modal/Modal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { FaTimes } from "react-icons/fa";
import ModalLeft from "./ModalLeft";
import ModalRightStep1 from "./ModalRightStep1";
import ModalRightStep2 from "./ModalRightStep2";
import { format } from "date-fns";
import toast from "react-hot-toast";
import {data_en, data_id} from "./data";

export default function WhatWeDo() {
  const {baseUrl, lang} = useContext(AppContext);
  const [whatWeDo, setWhatWeDo] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [expandedParent, setExpandedParent] = useState(-1);
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
    setStep(1);
    setName("");
    setEmail("");
    setPhoneNumber("");
    setIsSubmitting(false);
  }

  const sendEmail = (date) => {
    setIsSubmitting(true);
    fetch("https://futr.asia/api/email-form", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: "hello@futr.asia",
        name,
        phone_number: phoneNumber,
        user_email: email,
        date: format(date, "yyyy-MM-dd"),
        category: selectedItem.parent,
        subcategory: selectedItem.item.name
      }),
    })
    .then((res) => {
      return res.text();
    })
    .then((res) => {
      toast.success("Request sent successfully.");
    })
    .catch((err) => {
      toast.error(err.message);
    })
    .finally(() => {
      handleCloseModal();
    });
  }

  const handleItemClick = (item, parent) => {
    setShowModal(true);
    setSelectedItem({item: item, parent: parent});
  }

  const handleExpandedParent = (index) => {
    setExpandedParent(expandedParent === index ? -1 : index);
  }

  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/what-we-dos?_locale=${lang}&_sort=id`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;

    result.forEach(item => {
      item.htmlId = item.title.toLowerCase().split(" ").join("_");
    });
    setWhatWeDo(result);
  }

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (<>
    <div className={style["container"]}>
      <div className={style["background-top"]}></div>

      <h4 className={`BakbakOne ${style["title"]}`}>
        <span className={style["highlight"]}>WHAT</span> WE DO!
      </h4>

      <div className={`${style["content-wrapper"]} ${expandedParent === -1 ? "" : style["expanded"]}`}>
        {whatWeDo.map((item, index) =>
          <React.Fragment key={index}>
            <div className={`${style["content"]} ${index%2 === 0 ? style["bg-purple"] : style["bg-yellow"]}`}>
              <div className={style["content-title"]}>
                {item.title}
              </div>
              <div className={style["content-description"]}>
                <ReactMarkdown>
                  {item.short_description}
                </ReactMarkdown>
              </div>
              <div
                id={`whatwedo__${item.htmlId}`}
                className={style["learn-more"]}
                onClick={() => handleExpandedParent(index)}
              >
                {index === expandedParent ? "-" : "+"}
              </div>
            </div>
            {index === expandedParent && <div className={style["sector-wrapper"]}>
              {item.sectors && item.sectors.map((sector, sectorIndex) =>
                <p key={`sector_${sectorIndex}`}
                  onClick={() => handleItemClick(sector, item.title)}
                  className={`${style["sector"]} ${style[Math.round(sectorIndex/2)%2 === 0 ? "bg-purple" : "bg-yellow"]}`}
                >
                  {sector.name}
                </p>
              )}
            </div>}
          </React.Fragment>
        )}
      </div>
    </div>
    {
      showModal &&
      <Modal show={showModal} onClose={handleCloseModal} isBlank={true}>
        <div className={style["modal-container"]}>
          <ModalLeft data={selectedItem} />
          <div className={`${style["modal-content-wrapper"]} ${style["modal-right"]}`}>
            <h4 className={style["modal-title"]}>
              {lang === "id" ? "Siap mengenal kami lebih jauh?" :
                <>Ready to check <span>us</span> out?</>
              }
            </h4>
            <div className={style["stepper"]}>
              <div className={`${style["step"]} ${step === 1 ? style["active"] : style["clickable"]}`}
                onClick={() => setStep(1)}
              >
                <div className={style["step-number"]}><span>1</span></div>
                <div className={style["step-name"]}>
                  {lang === "id" ? "Isi data diri di bawah ini" : "Fill in your details"}
                </div>
              </div>
              <div className={`${style["step"]} ${step === 2 ? style["active"] : ""}`}>
                <div className={style["step-number"]}><span>2</span></div>
                <div className={style["step-name"]}>
                  {lang === "id" ? "Atur jadwal pertemuan" : "Schedule a meeting"}
                </div>
              </div>
            </div>
            {step === 1 && <ModalRightStep1
              showModal={showModal}
              name={name}
              onNameChange={(val) => setName(val)}
              email={email}
              onEmailChange={(val) => setEmail(val)}
              phoneNumber={phoneNumber}
              onPhoneNumberChange={(val) => setPhoneNumber(val)}
              onSubmit={() => setStep(2)} />
            }
            {step === 2 && <ModalRightStep2
              showModal={showModal}
              isSubmitting={isSubmitting}
              onSubmit={sendEmail} />
            }
          </div>
          <FaTimes className={style["modal-close"]} onClick={handleCloseModal} />
        </div>
      </Modal>
    }
  </>)
}