import style from "./Briefing.module.css";
import image from "assets/images/bg_briefing.png";
import { Carousel } from "react-responsive-carousel";
import iconQuote from "assets/images/icon_double_quote.png";
import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { data_en, data_id } from "./data";

export default function Briefing() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/about-company?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (
    <div className={`bgc-green ${style["container"]}`}>
      <img src={image} className={style["bg-image"]} />
      {data && <Carousel
        showStatus={false}
        autoPlay={true}
        interval={5000}
        showThumbs={false}
        swipeable={false}
        infiniteLoop={true}
        renderIndicator={(clickHandler, selected) => {
          return (
            <div
              style={{
                backgroundColor: selected ? "#CAFC0E" : "#333",
                padding: "0.1em",
                borderBottom: "1em solid " + selected ? "#CAFC0E" : "#333",
                marginRight: "0.5em",
                width: "1.5em",
                display: "inline-block",
                cursor: "pointer"
              }}
              onClick={clickHandler}
            ></div>
          );
        }}
      >
        <div className={style["flex-container"]}>
          <div className={`${style["flex-item"]} ${style["flex-left"]}`}>
            <h1 className={`BakbakOne ${style["title"]}`}>
              {lang === "en" && <><strong>PT LINI IMAJI KREASI EKOSISTEM</strong> is an APAC-based technology company that helps companies increase revenue and profits by</>}
              {lang === "id" && <><strong>PT LINI IMAJI KREASI EKOSISTEM</strong> MERUPAKAN PERUSAHAAN BERBASIS TEKNOLOGI DI ASIA PASIFIK YANG MEMBANTU PERUSAHAAN DALAM MENINGKATKAN PENDAPATAN DAN KEUNTUNGAN MELALUI</>}
            </h1>
          </div>
          <div className={`${style["flex-item"]} ${style["flex-right"]}`}>
            <ul className={style["points"]}>
              {data.points.map((point, index) =>
                <li className={style["point"]} key={index}>
                  <div className={style["point-inner"]}>
                    <span>{(index+1).toString().padStart(2, "0")}.</span>
                    <ReactMarkdown>{point.item}</ReactMarkdown>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className={style["quote-container"]}>
          <div className={style["quote"]}>
            <img src={iconQuote} className={style["quote-open"]} />
            <img src={iconQuote} className={style["quote-close"]} />
            {data.quote}
          </div>
          <p className={`BakbakOne ${style["quoter"]}`}>
            {data.author}
          </p>
        </div>
      </Carousel>}
    </div>
  )
}