import AppContext from "AppContext";
import { useContext, useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import style from "./Upcoming.module.css";
import { data_en, data_id } from "./data";

export default function Upcoming() {
  const [flipState, setFlipState] = useState();
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/upcoming-projects?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id : data_en;
    result.projects.forEach(project => {
      project.htmlId = project.label.toLowerCase().split(" ").join("_");
    });
    setData(result);
    setFlipState(Array(result.projects.length).fill(false));
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  const toggleFlipState = (index) => {
    const newState = [...flipState];
    newState[index] = !newState[index];
    setFlipState(newState);
  }

  return (<>
    {data && <div className={style["container"]}>
      <h1 className={style["title"]}>
        <span className={style["highlight"]}>UPCOMING</span>
        <span>
          <strong>FUTR</strong> PROJECTS
        </span>
      </h1>
      <div className={style["projects"]}>
        {flipState && data.projects.map((project, index) =>
          <div className={`${style["flipper"]} ${flipState[index] === true ? style["flipped"] : ""}`} key={index}>
            <div className={`${style["front"]} ${style[Math.round(index/2)%2 === 0 ? "bg-purple" : "bg-yellow"]}`}>
              <h4 className={`${style["name"]} BakbakOne`}>
                {project.label}
              </h4>
              <button type="button"
                id={`upcoming__${project.htmlId}`}
                className={style["btn-toggler"]}
                onClick={() => toggleFlipState(index)}
              >
                {data.learn_more}
              </button>
            </div>
            <div className={style["back"]} onClick={() => toggleFlipState(index)}>
              <h4 className={`${style["name"]} BakbakOne`}>
                {project.label}
              </h4>
              <div className={style["description"]}>
                <ReactMarkdown>
                  {project.value}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>}
  </>)
}