export const data_en = [
  {
  "id":10,
  "title":"SOCIAL COMMERCE TECHNOLOGY",
  "short_description":"We **FUTURPHUTURE** enables businesses to reach targeted consumers where they spend most of their time and connect to build brand awareness and drive sales by leveraging the power of social networks.",
  "locale":"en",
  "published_at":"2022-11-10T08:22:15.853Z",
  "created_at":"2022-11-10T08:21:30.638Z",
  "updated_at":"2023-03-06T08:42:07.602Z",
  "fields":[
  {
  "id":12,
  "item":"E-Commerce",
  "description":null
  },
  {
  "id":13,
  "item":"Mobile App",
  "description":null
  },
  {
  "id":14,
  "item":"Web Portal",
  "description":null
  },
  {
  "id":15,
  "item":"CRM",
  "description":null
  },
  {
  "id":16,
  "item":"Artificial Intelligence",
  "description":null
  },
  {
  "id":17,
  "item":"Web3",
  "description":null
  },
  {
  "id":18,
  "item":"Block-chain",
  "description":null
  },
  {
  "id":19,
  "item":"NFTs",
  "description":null
  },
  {
  "id":20,
  "item":"Decentralized Infrastructure",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":8,
  "name":"E-Commerce",
  "description":"##### what is **E-Commerce**?\nE-commerce (electronic commerce) is the buying and selling of goods and services, or the transmitting of funds or data, over an electronic network, primarily the internet. These business transactions occur either as business-to-business (B2B), business-to-consumer (B2C), consumer-to-consumer or consumer-to-business, which can be in the form of branded online shop or a marketplace. Our E-commerce systems can be tailored to suit businesses of all sizes, from small startups to large multinational corporations. These includes features such as product catalogs, shopping carts, and order processing systems. Overall, e-commerce is a critical service for businesses that want to compete in the digital economy, providing new opportunities for growth, improved customer engagement, and greater operational efficiency.\n  \n  \nYou would use this service to …\n- ```grow your company’s market cap and enterprise value.```\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":9,
  "name":"Mobile App",
  "description":"##### what is **MOBILE APP**?\nA mobile application or app is a computer program or software application designed to run on a mobile device such as a phone, tablet, or even a watch. With a robust team of avid programmers, passionate designers, and experienced business advisors, we will make sure that everything is handled as per the highest standards. We employ a tried-and-tested mobile app development process, which guarantees the best value for our clients.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":10,
  "name":"Web Portal",
  "description":"##### what is **WEB PORTAL**? \nA web portal is a web-based platform that collects information from different sources into a single user interface and presents users with the most relevant information for their context. Over time, simple web portals have evolved into portal platforms that support digital customer experience initiatives. That’s why our experienced web designers and web developers design and build customised mobile-friendly and compelling portals with conversions in mind; incorporating key elements that not only meets your business requirements, but encourages your visitors to take action.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":11,
  "name":"CRM",
  "description":"##### what is **CRM**?\nCustomer Relationship Management (CRM) is a technology for managing all your company’s relationships and interactions with customers and potential customers. The goal is simple: Improve business relationships. We can help design a CRM system that helps companies stay connected to customers, streamline processes, and improve profitability.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```"
  },
  {
  "id":12,
  "name":"Artificial Intelligence",
  "description":"##### what is **ARTIFICIAL INTELLIGENCE**?\nWe solve the toughest human problems through the transformative power of artificial intelligence. Our AI engineers use bespoke machine learning models to develop original solutions to your critical business challenges. We add value to your business and create business solutions that ace precision, speed, and effectiveness. It's not just a technological evolution but an enhancement to your business operation for rewarding output.\n  \n  \n```You would use this service to …```\n- ```grow your company’s market cap and enterprise value.```\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":13,
  "name":"Web3",
  "description":"##### what is **WEB3**?\nWeb3 is an idea for a new iteration of the World Wide Web which incorporates concepts such as decentralization, blockchain technologies, and token-based economics. Web3 allows for secure and direct peer-to-peer transactions, eliminates intermediaries, and enables trustless interactions between users. Overall, Web3 is a powerful and transformative service that has the potential to revolutionize the way we interact with the internet and with each other.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers. ```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":14,
  "name":"Block-chain",
  "description":"##### what is **BLOCK-CHAIN**?\nBlockchain is a digital ledger technology that uses cryptography to secure and validate transactions, and to create a decentralized, tamper-proof record of those transactions. It is the technology that underpins cryptocurrencies like Bitcoin, but it has many other potential uses, such as in supply chain management, digital identity, and smart contracts. Our service allows your business to use cloud-based solutions to develop, host and adopt your own blockchain applications, smart contracts and other relevant functions on the blockchain while managing all the required tasks and activities to keep the infrastructure up and running.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":15,
  "name":"NFTs",
  "description":"##### what is **NFT**?\nAn NFT is a digital asset that represents real-world objects like art, music, in-game items and videos. They are bought and sold online, frequently with cryptocurrency, and they are generally encoded with the same underlying software as many cryptos. Our team of experts help you see beyond the buzzwords and better apprehend the actual opportunities behind the trends to position yourself in the smartest and most cost-efficient way. We build NFT assets that actually make a difference.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":16,
  "name":"Decentralized Infrastructure",
  "description":"##### what is **DECENTRALIZED INFRASTRUCTURE**?\nDecentralized infrastructure is a service that leverages the power of blockchain technology to create a distributed and open network, eliminating the need for centralized intermediaries. This service provides a range of benefits, including increased security, improved data privacy, and more transparent and efficient transactions. Decentralized infrastructure can be used in a range of industries, such as finance, supply chain management, and social media, to enable more efficient and equitable systems.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":12,
  "locale":"id",
  "published_at":"2022-11-10T08:22:29.338Z"
  }
  ]
  },
  {
  "id":9,
  "title":"BIG DATA ANALYTICS",
  "short_description":"**PT LINI IMAJI KREASI EKOSISTEM** allows businesses to collect, process, and analyze large amounts of data in compliance with local data privacy law to gain insights and make better decisions.",
  "locale":"en",
  "published_at":"2022-11-10T08:16:25.553Z",
  "created_at":"2022-11-10T08:16:21.021Z",
  "updated_at":"2023-03-06T08:38:36.629Z",
  "fields":[
  {
  "id":6,
  "item":"Data Engineering",
  "description":null
  },
  {
  "id":7,
  "item":"Data Warehouse Management Service ",
  "description":null
  },
  {
  "id":8,
  "item":"Data Visualization",
  "description":null
  },
  {
  "id":9,
  "item":"Data Storytelling",
  "description":null
  },
  {
  "id":10,
  "item":"Applied Research",
  "description":null
  },
  {
  "id":11,
  "item":"Predictive Analytics",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":2,
  "name":"Data Engineering",
  "description":"##### what is **DATA ENGINEERING**?\nData engineering is the process of designing, building, and maintaining the infrastructure and systems needed to store, process, and analyse your data. This service strives to make sure that your data is accurate, accessible, and ready to be used for analysis and decision-making. It will include consulting, implementation and maintenance of the data infrastructure and pipeline, as well as training and support for using your data to gain insights for your business.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```"
  },
  {
  "id":3,
  "name":"Data Warehouse Management Service",
  "description":"##### what is **DATA WAREHOUSE MANAGEMENT SERVICE**?\nOur data warehouse service allows your business to easily store, manage, and analyse large amounts of data from multiple sources, providing insights that can help you drive better decision making, improve operations, and achieve cost savings. This service also includes processes for data cleaning, transformation, and loading to ensure the accuracy and completeness of the data. Overall, data warehouse management is a powerful service that can help businesses of all sizes improve their data management and decision-making capabilities.\n  \n  \n ```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers. ```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":4,
  "name":"Data Visualization",
  "description":"##### what is **DATA VISUALIZATION**?\nOur data visualization service enables your business to transform complex data into clear and actionable insights, providing a powerful tool for decision making, strategic planning and improved understanding of your key performance indicators. We will also advise on the best metrics to benchmark against using our in-house metric diagnosis methodologies.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- protect and store your valuable first party database against hackers. \n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":5,
  "name":"Data Storytelling",
  "description":"##### what is **DATA STORYTELLING**?\nOur data storytelling service enables you to use visualization and narrative techniques to communicate insights, information, and ideas in a clear, engaging, and compelling way. Data storytelling can be used in a variety of contexts, from business presentations to marketing campaigns. It is a way to make data more accessible and understandable, and to make sense of complex information. \n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":6,
  "name":"Applied Research",
  "description":"##### what is **APPLIED RESEARCH**? \nOur applied research service allows your business to tap on our deep expertise across industries to curate and gather first party data to third party data thus gaining actionable insights on business intelligence, customer intelligence, marketing intelligence and cultural intelligence. Applied research differs from basic research in that it is focused on solving real-world problems and producing tangible outcomes. This service involves collaboration between researchers, industry partners, and other stakeholders to ensure that the research is relevant, useful, and feasible. \n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":7,
  "name":"Predictive Analysis",
  "description":"##### what is PREDICTIVE ANALYTICS?\nOur predictive analytics service offers a fully customizable Business Intelligence (BI) solution with end-to-end capabilities, organizing, analysing, and presenting data in a way that lets even non-IT professionals gain insight and take action. It involves the use of historical data, real-time data streams, and other sources of information to predict outcomes with a high degree of accuracy. Overall, predictive analytics is a powerful service that can help businesses of all sizes to gain a competitive edge by leveraging the insights derived from their data.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":11,
  "locale":"id",
  "published_at":"2022-11-10T08:21:59.900Z"
  }
  ]
  },
  {
  "id":13,
  "title":"IMMERSIVE TECHNOLOGY",
  "short_description":"This suite of capabilities revolutionize the way people interact with digital content and technology. It can also enhance collaboration and communication, as well as provide new and innovative ways to solve complex problems and challenges.",
  "locale":"en",
  "published_at":"2022-11-10T08:22:57.001Z",
  "created_at":"2022-11-10T08:22:51.028Z",
  "updated_at":"2023-02-23T06:58:11.907Z",
  "fields":[
  {
  "id":21,
  "item":"Augmented Reality",
  "description":null
  },
  {
  "id":22,
  "item":"Virtual Reality",
  "description":null
  },
  {
  "id":23,
  "item":"Extended Reality",
  "description":null
  },
  {
  "id":24,
  "item":"Consumer Metaverse",
  "description":null
  },
  {
  "id":25,
  "item":"Industrial Metaverse",
  "description":null
  },
  {
  "id":26,
  "item":"Motion Sensors",
  "description":null
  },
  {
  "id":27,
  "item":"Anamorphic Projection",
  "description":null
  },
  {
  "id":28,
  "item":"Projection Mapping",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":17,
  "name":"Augmented Reality",
  "description":"##### what is **AUGMENTED REALITY**?\nAugmented reality (AR) is a technology that enhances the user's perception of the real world by overlaying digital information on top of it. It uses a combination of hardware and software to superimpose digital images, text, and other data onto the user's view of the real world. This can be done through a variety of devices, including smartphones, tablets, and specialized AR headsets. Our team can help you leverage the power of AR for your brand and build your new, digital worlds. With experience navigating projects from entertainment to retail, we can help you deliver experiences above and beyond every brief - whatever you want to build, we’ll make it happen.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":18,
  "name":"Virtual Reality",
  "description":"##### what is **VIRTUAL REALITY**?\nVirtual reality (VR) is a technology that creates a simulated environment that users can interact with in a seemingly real way. It typically requires the use of a specialized headset, such as an Oculus or a Vive, that tracks the user's head movements and displays images on a screen in front of their eyes. The user is fully immersed in the virtual environment and can interact with it using handheld controllers or other input devices. Our team can develop bespoke immersive technology solutions in this new digital age and we take great pride in helping you create and develop VR campaign strategies that achieve real, tangible user engagement and ultimately, results.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":19,
  "name":"Extended Reality",
  "description":"##### what is **EXTENDED REALITY**?\nExtended reality (XR) is a term that refers to a broad category of technologies that include virtual reality (VR), augmented reality (AR), and mixed reality (MR). It encompasses a range of different technologies that blur the lines between the real and virtual worlds, and allow users to interact with digital content in more immersive and realistic ways. XR can be used across various industries, such as Gaming, Training, Education, Healthcare, Product visualization and remote collaboration among others and we offer a tailored solution for each industry.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":20,
  "name":"Customer Metaverse",
  "description":"##### what is **CONSUMER METAVERSE**?\nThe consumer metaverse is a term used to describe a virtual world where users can interact with each other and with digital content in a seamless and immersive way. It is a vision of a fully-realized virtual reality where users can engage in a variety of activities, such as socializing, gaming, shopping, and more. It is essentially a digital space where users can explore, create, and connect with others in a way that is similar to the physical world. Our services helps your business to create and operate your own virtual worlds, or a platform for your consumers to access and participate in the metaverse.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- ```increase consumer awareness for your brands and products.```\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":21,
  "name":"Industrial Metaverse",
  "description":"##### what is **INDUSTRIAL METAVERSE**?\nThe industrial metaverse is a term used to describe a virtual world that is specifically designed for industrial and manufacturing applications. It can include virtual simulations, digital twins, and other technologies that allow companies to design, test, and optimize their products and processes in a virtual environment. By simulating real-world conditions and scenarios, we can provide this service to your business so that you can gain valuable insights and make data-driven decisions to improve efficiency, reduce costs, and increase productivity.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":22,
  "name":"Motion Sensor",
  "description":"##### what is **MOTION SENSOR**?\nA motion sensor, or motion detector, is an electronic device that uses a sensor to detect nearby people or objects. More brands have been elevating the motion-capture technology trend at events through custom, interactive installations such as LED pixel walls or even tunnels with animations and music. We can help brands use this technology in a way that’s meaningful in the context of the user experience.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":23,
  "name":"Anamorphic Projection",
  "description":"##### what is **ANAMORPHIC PROJECTION**?\nAnamorphic projection is a specialized form of projection mapping that uses optical illusions to create distorted images that appear normal when viewed from a certain angle. This service can be used for a variety of applications, such as advertising, art installations, or immersive experiences. By projecting onto surfaces that are irregular in shape, such as a curved wall or a sculptural element, anamorphic projection can create unique visual effects and add an element of surprise to any project. Overall, anamorphic projection is a visually stunning and attention-grabbing technique that can create a memorable experience for any audience.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":24,
  "name":"Projection Mapping",
  "description":"##### what is **PROJECTION MAPPING**?\nProjection mapping is a creative and innovative technology that projects digital images onto physical objects, buildings or surfaces, creating a unique visual experience. This service can be used for a variety of purposes, such as marketing, entertainment, or artistic expression. By transforming ordinary surfaces into dynamic and interactive displays, projection mapping allows for a new level of creativity and engagement with audiences. It can be used to create stunning visual effects, transform the look and feel of an event or space, or to enhance storytelling and brand messaging. Overall, projection mapping is a versatile and powerful tool for creating immersive experiences that captivate audiences and leave a lasting impression.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- ```provide engaging experiences for your target consumers.```\n- protect and store your valuable first party database against hackers. \n- increase your operation productivity and efficiency.\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":14,
  "locale":"id",
  "published_at":"2022-11-10T08:23:25.131Z"
  }
  ]
  },
  {
  "id":15,
  "title":"CYBER GOVERNANCE",
  "short_description":"**FUTR** also build a comprehensive and integrated approach that combines technical, organizational, and legal measures to manage and mitigate cyber risks to ensure the protection of digital assets and information.",
  "locale":"en",
  "published_at":"2022-11-10T08:23:46.121Z",
  "created_at":"2022-11-10T08:23:43.635Z",
  "updated_at":"2023-03-06T08:40:07.613Z",
  "fields":[
  {
  "id":29,
  "item":"Data Protection",
  "description":null
  },
  {
  "id":30,
  "item":"Risk Detection",
  "description":null
  },
  {
  "id":31,
  "item":"Incident Response Management",
  "description":null
  },
  {
  "id":32,
  "item":"Threat Mitigation and Reporting",
  "description":null
  },
  {
  "id":33,
  "item":"Pen Test",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":25,
  "name":"Data Protection",
  "description":"##### what is **DATA PROTECTION**?\nBeing the ONE STOP SHOP, END TO END approach for anything to do with data protection which includes the process of managing and controlling the availability, usability, integrity, and security of your businesses’ data. These services are designed to help businesses comply with data protection regulations and standards, such as the General Data Protection Regulation (GDPR) and local Personal Data Protection Policy (PDPC). No need to rip and replace. Our data protection service works with the tools and processes you already have—making it easier to get up and running in a matter of days.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers.```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":26,
  "name":"Risk Detection",
  "description":"##### what is **RISK DETECTION**?\nRisk detection refers to the process of identifying potential risks or threats to an organization and taking action to mitigate those risks. It involves analyzing data, monitoring systems, and applying various techniques to detect vulnerabilities and prevent potential issues before they occur. This can include detecting cybersecurity threats, fraud, or regulatory compliance issues. The service provides peace of mind to organizations by reducing risk, improving compliance, and avoiding potential legal and financial penalties. Overall, a risk detection service is a critical component of any organization's risk management strategy.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers.```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":27,
  "name":"Incident Response Management",
  "description":"##### what is **INCIDENT RESPONSE MANAGEMENT**?\nIncident response management is a service designed to help organizations detect and respond to cybersecurity incidents in a timely and effective manner. The service involves establishing a set of procedures and protocols to quickly identify and contain security breaches, minimize damage, and restore normal operations. This service is critical in today's digital landscape, where the frequency and sophistication of cyber-attacks are increasing. An effective incident response management service will involve a combination of technology, policies, and human expertise to ensure that incidents are detected, analyzed, and responded to quickly and effectively. In general, incident response management is a vital component of any organization's cybersecurity strategy.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers.```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":28,
  "name":"Threat Mitigation and Reporting",
  "description":"##### what is **THREAT MITIGATION AND REPORTING**?\nThreat mitigation and reporting is a service designed to identify and address potential security threats to an organization's network, systems, and data. The service involves analyzing and monitoring system logs, network traffic, and other data sources to identify potential threats. Once threats are identified, the service provides a range of mitigation strategies, including patch management, access control, and security awareness training. Additionally, the service provides detailed reporting on the types and frequency of threats, helping organizations to improve their security posture over time. Ultimately, this service helps to minimize risk, protect valuable data, and ensure business continuity.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers.```\n- ```increase your operation productivity and efficiency.```\n"
  },
  {
  "id":29,
  "name":"Pen Test",
  "description":"##### what is **PEN TEST**?\nPenetration testing, or \"pen test\" for short, is a service designed to identify vulnerabilities in an organization's network, systems, and applications. This service involves attempting to exploit potential weaknesses in an organization's defenses to identify areas where an attacker could gain unauthorized access. Pen tests are conducted by our trained cybersecurity professionals using a range of techniques, including social engineering, network scanning, and application testing. Once vulnerabilities are identified, the pen test report provides recommendations on how to address the identified weaknesses. Ultimately, this service helps to improve an organization's overall security posture, mitigate risks, and reduce the likelihood of successful attacks.\n  \n  \n```You would use this service to …```\n- grow your company’s market cap and enterprise value.\n- increase consumer awareness for your brands and products.\n- provide engaging experiences for your target consumers.\n- ```protect and store your valuable first party database against hackers.```\n- increase your operation productivity and efficiency.\n"
  },
  {
  "id":58,
  "name":null,
  "description":null
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":16,
  "locale":"id",
  "published_at":"2022-11-10T08:24:04.956Z"
  }
  ]
  }
]

export const data_id = [
  {
  "id":12,
  "title":"SOCIAL COMMERCE TECHNOLOGY",
  "short_description":"Kami **FUTURPHUTURE** menjangkau target konsumen ke tempat mereka menghabiskan sebagian besar waktu mereka, serta saling terhubung untuk membangun *brand awareness* dan mendorong penjualan dengan memanfaatkan kekuatan jaringan sosial.",
  "locale":"id",
  "published_at":"2022-11-10T08:22:29.338Z",
  "created_at":"2022-11-10T08:22:25.586Z",
  "updated_at":"2023-03-06T08:41:19.145Z",
  "fields":[
  {
  "id":93,
  "item":"E-Commerce",
  "description":null
  },
  {
  "id":94,
  "item":"Mobile App",
  "description":null
  },
  {
  "id":95,
  "item":"Web Portal",
  "description":null
  },
  {
  "id":96,
  "item":"CRM",
  "description":null
  },
  {
  "id":97,
  "item":"Artificial Intelligence",
  "description":null
  },
  {
  "id":98,
  "item":"Web3",
  "description":null
  },
  {
  "id":99,
  "item":"Block-chain",
  "description":null
  },
  {
  "id":100,
  "item":"NFTs",
  "description":null
  },
  {
  "id":101,
  "item":"Decentralized Infrastructure",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":49,
  "name":"E-Commerce",
  "description":"##### Apa itu **E-COMMERCE**?\nE-commerce (perdagangan elektronik) adalah proses jual beli barang dan jasa, atau perpindahan dana dan data, melalui jaringan elektronik, terutama internet. Transaksi bisnis ini terjadi baik sebagai business-to-business (B2), business-to-consumer (B2C), consumer-to-consumer (C2C), atau consumer-to-business (C2B), yang dapat berupa *branded online shop* atau *marketplace*. Sistem E-Commerce kami dapat disesuaikan dengan beragam bisnis, mulai dari startup kecil hingga perusahaan besar multinasional. Sistem ini mencakup beberapa fitur, diantaranya katalog produk, keranjang belanja, dan sistem proses pemesanan. Secara keseluruhan, e-commerce adalah layanan penting bagi sebuah bisnis yang ingin bersaing dalam ekonomi digital, memberikan peluang baru untuk pertumbuhan, meningkatkan customer engagement, dan efisiensi operasional yang lebih besar.\n  \n  \nAnda dapat menggunakan layanan ini untuk…\n- ```Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.```\n- ```Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":50,
  "name":"Mobile App",
  "description":"##### Apa itu **MOBILE APP**?\nAplikasi seluler atau apps adalah program komputer atau *software application* yang dirancang untuk digunakan dalam perangkat seluler seperti ponsel, tablet, atau bahkan jam tangan. Dengan tim programmer dan desainer yang andal, serta *business advisor* yang berpengalaman, kami memastikan bahwa setiap karya dan pekerjaan yang kami lakukan berlandaskan pada kualitas serta standar mutu yang tinggi. Kami menggunakan proses pengembangan aplikasi seluler yang telah diuji coba, sehingga kami dapat menjamin *value* terbaik bagi klien kami.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":51,
  "name":"Web Portal",
  "description":"##### Apa itu **WEB PORTAL**?\nWeb portal merupakan platform berbasis web yang mengumpulkan informasi dari berbagai sumber ke dalam interface pengguna dan menyajikan informasi yang paling relevan kepada pengguna sesuai dengan kebutuhan atau preferensi mereka. Seiring waktu, portal web sederhana telah berkembang menjadi platform portal yang mendukung inisiatif pengalaman pelanganggan digital. Itulah mengapa para *web designer* dan *web developer* kami merancang serta membangun *customised mobile-friendly* dan portal yang menarik dengan mempertimbangkan konversi berikut; menggabungkan elemen utama yang sudah disesuaikan dengan kebutuhan bisnis Anda serta juga mendorong pelanggan untuk mengambil tindakan.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":52,
  "name":"CRM",
  "description":"##### Apa itu **CRM**?\n*Customer Relationship Management* (CRM) adalah sebuah teknologi yang berguna dalam mengelola hubungan dan interaksi antara perusahaan dengan pelanggan dan calon pelanggannya. Tujuannya sederhana saja: Meningkatkan hubungan bisnis. Kami dapat membantu Anda merancang sistem CRM yang berguna bagi perusahaan untuk tetap terhubung dengan pelanggan, merampingkan proses, serta meningkatkan profitabilitas.\n  \n  \nAnda dapat menggunakan layanan ini untuk…\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":53,
  "name":"Artificial Intelligence",
  "description":"##### Apa itu **ARTIFICIAL INTELLIGENCE**?\nKami memecahkan permasalahan sulit manusia melalui kekuatan transformasi dari *artificial intelligence*. *AI Engineering* kami menggunakan model machine learning khusus untuk mengembangkan solusi orisinil bagi permasalahan dan tantangan bisnis Anda. Kami menambah nilai pada bisnsi Anda dan menciptakan solusi bisnis yang mengutamakan presisi, kecepatan, dan efektivitas. Ini bukan hanya sebuah evolusi teknologi semata, tapi juga peningkatan operasional bisnis Anda untuk mendapatkan hasil yang bernilai.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- ```Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.```\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n\n"
  },
  {
  "id":54,
  "name":"Web3",
  "description":"##### Apa itu **WEB3**?\nWeb3 adalah ide iterasi baru dari *World Wide Web* yang menggabungkan konsep desentralisasi, teknologi blockchain, dan ekonomi berbasis token. Web3 memungkinkan transaksi peer-to-peer secara langsung dan aman, menghilangkan perantara, dan memungkinkan *trustless interaction* antar pengguna. Secara keseluruhan, Web3 adalah layanan transformatif yang berpotensi dalam merevolusi cara kita berinteraksi dengan internet dan juga terhadap satu sama lain.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari *Hackers*```\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":55,
  "name":"Block-chain",
  "description":"##### Apa itu **BLOCK-CHAIN**?\nBlockchain adalah pengembangan dari sebuah teknologi *digital ledger* yang memanfaatkan *cryptography* untuk mengamankan dan memvalidasi transaksi, serta menciptakan catatan transaksi yang terdesentralisasi dan tidak dapat dirusak. Blockchain merupakan teknologi yang menopang *cryptocurrencies* seperti Bitcoin, tapi di sisi lain juga memiliki potensi manfaat yang besar, seperti dalam *supply chain management*, identitas digital, dan *smart contracts*. Layanan kami memungkinkan bisnis Anda menggunakan solusi berbasis cloud untuk mengembangkan, meng-hosting, dan mengadopsi aplikasi blockchain Anda sendiri, *smart contracts*, dan fungsi relevan lainnya sambil mengelola semua tugas dan aktivitas yang dibutuhkan untuk menjaga keberjalanan infrastruktur.\n  \n  \nAnda dapat menggunakan layanan ini untuk…\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n\n"
  },
  {
  "id":56,
  "name":"NFTs",
  "description":"##### Apa itu **NFT**?\nNFT adalah aset digital yang mewakili objek dunia nyata seperti seni, musik, item dalam game, dan video. Hal-hal ini diperjualbelikan secara online dengan menggunakan mata uang crypto, dan umumnya dikodekan dengan menggunakan *software* dasar yang sama dengan kebanyakan crypto. Tim ahli kami akan membantu Anda untuk melihat jauh dari sekedar *buzzword* dan lebih memahami peluang nyata dibalik sebuah tren untuk memposisikan diri Anda secara cerdas dan hemat biaya. Kami membangun aset NFT yang benar-benar membuat perbedaan.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":57,
  "name":"Decentralized Infrastructure",
  "description":"##### Apa itu **DECENTRALIZED INFRASTRUCTURE**?\nInfrastruktur terdesentralisasi adalah layanan yang memanfaatkan kekuatan teknologi *blockchain* untuk membuat jaringan terdistribusi dan terbuka, menghilangkan kebutuhan akan perantara. Layanan ini menyediakan berbagai keuntungan, diantaranya peningkatan keamanan, pengelolaan data privasi yang lebih baik, serta transaksi yang lebih transparan dan efektif. Infrastruktur terdesentralisasi dapat digunakan di berbagai macam industri, seperti keuangan, supply chain management, dan media sosial, untuk membuat sistem yang lebih adil dan efisien.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":10,
  "locale":"en",
  "published_at":"2022-11-10T08:22:15.853Z"
  }
  ]
  },
  {
  "id":11,
  "title":"BIG DATA ANALYTICS",
  "short_description":"**PT LINI IMAJI KREASI EKOSISTEM** membantu bisnis untuk mengumpulkan, memproses, dan menganalisa data dengan jumlah besar sesuai dengan undang-undang privasi data lokal dalam mendapatkan *insight* serta membuat keputusan yang lebih baik.",
  "locale":"id",
  "published_at":"2022-11-10T08:21:59.900Z",
  "created_at":"2022-11-10T08:21:57.022Z",
  "updated_at":"2023-03-06T08:38:23.032Z",
  "fields":[
  {
  "id":74,
  "item":"Data Engineering",
  "description":null
  },
  {
  "id":75,
  "item":"Data Warehouse Management Service ",
  "description":null
  },
  {
  "id":76,
  "item":"Data Visualization",
  "description":null
  },
  {
  "id":77,
  "item":"Data Storytelling",
  "description":null
  },
  {
  "id":78,
  "item":"Applied Research",
  "description":null
  },
  {
  "id":79,
  "item":"Predictive Analytics",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":30,
  "name":"Data Engineering",
  "description":"##### Apa itu **DATA ENGINEERING**?\nData engineering adalah proses mendesain, membangun, dan memelihara infrastuktur serta sistem yang diperlukan untuk menyimpan, memproses, serta menganalisa data. Layanan ini memastikan bahwa data yang Anda dapatkan akurat, mudah diakses, dan siap digunakan untuk analisis dan pengambilan keputusan. Hal ini mencakup konsultasi, implementasi, dan pemeliharaan infrastruktur dan jalur data, serta pelatihan dan bantuan dalam penggunaan data Anda untuk menghasilkan *insight* terbaik bagi bisnis Anda.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":31,
  "name":"Data Warehouse Management Service",
  "description":"##### Apa itu **DATA WAREHOUSE MANAGEMENT SERVICE**?\nLayanan Data Warehouse Management Service kami menyediakan kemudahan bagi bisnis Anda untuk menyimpan, mengelola, dan menganalisa data dalam jumlah besar dari berbagai sumber, memberikan *insight *yang dapat membantu Anda dalam mengambil keputusan yang lebih baik, meningkatkan produktivitas operasional, serta mencapai cost-saving. Layanan ini juga mencakup jasa pembersihan, tranformasi, dan loading data untuk memastikan data menjadi lebih akurat dan lengkap. Secara keseluruhan, *data warehouse management* adalah layanan mutakhir yang dapat membantu segala jenis bisnis dalam membenahi manajemen datanya serta meningkatkan kemampuannya dalam proses pengambilan keputusan.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari *Hackers*```\n- ``` Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":32,
  "name":"Data Visualization",
  "description":"##### Apa itu **DATA VISUALIZATION**?\nLayanan *data visualization* kami memungkinan bisnis Anda untuk mengubah data yang kompleks menjadi suatu *insight* yang mudah dipahami serta bermanfaat sebagai dasar eksekusi bisnis Anda. Kami menyediakan *tools* ampuh yang dapat membantu Anda dalam mengambil keputusan, perencanaan strategis, serta pemahaman yang lebih baik atas indikator kinerja utama Anda. Kami juga akan menyarankan metrik-metrik terbaik yang dapat dijadikan tolak ukur dalam penggunaan metodologi diagnosis metrik internal kami.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":33,
  "name":"Data Storytelling",
  "description":"##### Apa itu **DATA STORYTELLING**?\nLayanan *data storytelling* kami memungkinkan Anda untuk menggunakan teknik visualisasi dan naratif dalam mengkomunikasikan *insight*, informasi, beserta ide-ide secara jelas, menarik, dan atraktif. *Data storytelling* dapat digunakan di berbagai konteks, mulai dari presentasi bisnis hingga *marketing campaign*. Cara ini membuat data Anda menjadi lebih mudah diakses dan dipahami, serta menyederhanakan informasi yang bersifat kompleks.\n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan brand awareness untuk untuk brand, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  },
  {
  "id":34,
  "name":"Applied Research",
  "description":"##### Apa itu **APPLIED RESEARCH**?\nLayanan *applied research* kami memungkinkan Anda untuk memanfaatkan keahlian kami di seluruh industri untuk menyusun dan mengumpulkan data dari pihak pertama hingga data pihak ketiga untuk memperoleh *insight* mengenai *business inteligence*, *customer intelligence*, *marketing intelligence*, dan *cultural intelligence*. *Applied research* berbeda dibandingkan penelitian pada umumnya, karena *applied research* berfokus pada pemecahan masalah di dunia nyata dan memberikan hasil yang *tangible*. Layanan ini melibatkan kolaborasi antar peneliti, mitra industri, serta para stakeholder lainnya untuk memastikan penelitian tersebut relevan, layak, dan bermanfaat.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan brand awareness untuk untuk brand, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  },
  {
  "id":35,
  "name":"Predictive Analysis",
  "description":"##### Apa itu **PREDICTIVE ANALYTICS**?\nLayanan *predictive analytics* kami menawarkan solusi *end-to-end Business Intelligence* (BI) dalam mengorganisir, menganalisis, serta menyajikan data yang bahkan profesional non-IT pun dapat dengan mudah memahami *insight* tersebut dan tahu apa yang harus dilakukan selanjutnya. Cara ini melibatkan penggunaan data historis, data *real-time*, dan sumber informasi lainnya untuk memprediksi hasil dengan tingkat akurasi yang tinggi. Secara keseluruhan, *predictive analytic* adalah sebuah layanan andal yang dapat membantu beragam jenis bisnis mendapatkan keunggulan kompetitif dengan memanfaatkan *insight* yang diperoleh dari data mereka.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan brand awareness untuk untuk brand, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":9,
  "locale":"en",
  "published_at":"2022-11-10T08:16:25.553Z"
  }
  ]
  },
  {
  "id":16,
  "title":"CYBER GOVERNANCE",
  "short_description":"**FUTR** juga melakukan pendekatan komprehensif dan terintegrasi yang menggabungkan langkah-langkah teknis, organisasi, dan hukum untuk mengelola serta memitigasi risiko dunia maya guna memastikan semua aset juga informasi digital terlindungi.",
  "locale":"id",
  "published_at":"2022-11-10T08:24:04.956Z",
  "created_at":"2022-11-10T08:24:02.542Z",
  "updated_at":"2023-03-06T08:39:12.493Z",
  "fields":[
  {
  "id":80,
  "item":"Data Protection",
  "description":null
  },
  {
  "id":81,
  "item":"Risk Detection",
  "description":null
  },
  {
  "id":82,
  "item":"Incident Response Management",
  "description":null
  },
  {
  "id":83,
  "item":"Threat Mitigation and Reporting",
  "description":null
  },
  {
  "id":84,
  "item":"Pen Test",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":36,
  "name":"Data Protection",
  "description":"##### apa itu **DATA PROTECTION**?\nSebagai *ONE STOP SHOP* dengan pendekatan *END TO END* untuk segala hal yang berhubungan dengan perlindungan data, kami menyediakan layanan pengelolaan serta pengendalian ketersediaan, kegunaan, integritas, dan keamanan data bisnis Anda. Layanan ini dirancang untuk membantu bisnis dalam mematuhi regulasi dan standar perlindungan data, seperti *General Data Protection Regulation* (*GDPR*) dan kebijakan *Personal Data Protection* (*PDPC*) lokal. Tidak perlu melakukan penggantian sistem. Layanan perlindungan data kami dapat berfungsi dengan alat dan proses yang Anda miliki saat ini, sehingga lebih mudah untuk diimplementasikan hanya dalam beberapa hari saja.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari Hackers```\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":37,
  "name":"Risk Detection",
  "description":"##### apa itu **RISK DETECTION**?\n*Risk detection* mengacu pada proses mengidentifikasi potensi risiko atau ancaman terhadap suatu organisasi dan mengambil tindakan untuk memitigasi risiko tersebut. Hal ini melibatkan analisis data, pemantauan sistem, dan penerapan berbagai teknik untuk mendeteksi kerentanan dan mencegah masalah potensial sebelum terjadi. Ini dapat mencakup mendeteksi ancaman keamanan siber, kecurangan, atau masalah kepatuhan regulasi. Layanan ini memberikan kedamaian pikiran kepada organisasi dengan mengurangi risiko, meningkatkan kepatuhan, dan menghindari potensi sanksi hukum dan keuangan. Secara keseluruhan, layanan deteksi risiko adalah komponen penting dari strategi manajemen risiko setiap organisasi.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari Hackers```\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":38,
  "name":"Incident Response Management",
  "description":"##### apa itu **INCIDENT RESPONSE MANAGEMENT**?\n*Incident response management* adalah layanan yang dirancang untuk membantu organisasi mendeteksi dan menanggapi insiden keamanan *cyber* dengan cepat dan efektif. Layanan ini melibatkan pembuatan serangkaian prosedur dan protokol untuk dengan cepat mengidentifikasi dan mengendalikan pelanggaran keamanan, meminimalkan kerusakan, dan mengembalikan operasi normal. Layanan ini sangat penting dalam lanskap digital saat ini, di mana frekuensi dan kompleksitas serangan siber semakin meningkat. Layanan manajemen respons insiden yang efektif akan melibatkan kombinasi teknologi, kebijakan, dan keahlian manusia untuk memastikan bahwa insiden terdeteksi, dianalisis, dan ditanggapi dengan cepat dan efektif. Secara umum, manajemen respons insiden adalah komponen vital dari strategi keamanan *cyber* setiap organisasi.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari Hackers```\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":39,
  "name":"Threat Mitigation and Reporting",
  "description":"##### apa itu **THREAT MITIGATION AND REPORTING**?\n*Threat mitigation and reporting* adalah layanan yang dirancang untuk mengidentifikasi dan mengatasi potensi ancaman keamanan pada jaringan, sistem, dan data sebuah organisasi. Layanan ini melibatkan analisis dan pemantauan catatan sistem, lalu lintas jaringan, dan sumber data lainnya untuk mengidentifikasi ancaman potensial. Setelah ancaman teridentifikasi, layanan tersebut menyediakan berbagai strategi mitigasi, termasuk *patch management*, pengendalian akses, dan *security awareness training*. Selain itu, layanan ini memberikan laporan terperinci tentang jenis dan frekuensi ancaman, membantu organisasi untuk meningkatkan posisi keamanan mereka dari waktu ke waktu. Secara keseluruhan, layanan ini membantu untuk meminimalkan risiko, melindungi data berharga, dan memastikan kelangsungan bisnis.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari Hackers```\n- ```Meningkatkan produktivitas serta efisiensi operasional Anda.```\n"
  },
  {
  "id":40,
  "name":"Pen Test",
  "description":"##### apa itu **PEN TEST**?\n*Penetration testing* atau \"*pen test*\" singkatnya, adalah layanan yang dirancang untuk mengidentifikasi kerentanan pada jaringan, sistem, dan aplikasi organisasi. Layanan ini melibatkan upaya untuk mengeksploitasi kelemahan potensial dalam pertahanan organisasi untuk mengidentifikasi area di mana penyerang dapat mendapatkan akses tidak sah. Pen test dilakukan oleh profesional keamanan cyber terlatih kami dengan menggunakan berbagai teknik, termasuk rekayasa sosial, pemindaian jaringan, dan pengujian aplikasi. Setelah kerentanan teridentifikasi, laporan *pen test* memberikan rekomendasi tentang bagaimana mengatasi kelemahan yang teridentifikasi. Pada akhirnya, layanan ini membantu meningkatkan posisi keamanan organisasi secara keseluruhan, mengurangi risiko, dan mengurangi kemungkinan serangan yang berhasil.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- Memberikan pengalaman menarik bagi target konsumen Anda.\n- ```Melindungi dan menyimpan data berharga Anda dari Hackers```\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":15,
  "locale":"en",
  "published_at":"2022-11-10T08:23:46.121Z"
  }
  ]
  },
  {
  "id":14,
  "title":"IMMERSIVE TECHNOLOGY",
  "short_description":"Serangkaian kemampuan yang merevolusi cara orang berinteraksi dengan konten dan teknologi digital. Hal ini dapat meningkatkan kolaborasi dan komunikasi, memberikan cara yang baru dan inovatif dalam memecahkan masalah serta tantangan yang kompleks.",
  "locale":"id",
  "published_at":"2022-11-10T08:23:25.131Z",
  "created_at":"2022-11-10T08:23:20.428Z",
  "updated_at":"2023-02-23T07:02:41.633Z",
  "fields":[
  {
  "id":85,
  "item":"Augmented Reality",
  "description":null
  },
  {
  "id":86,
  "item":"Virtual Reality",
  "description":null
  },
  {
  "id":87,
  "item":"Extended Reality",
  "description":null
  },
  {
  "id":88,
  "item":"Consumer Metaverse",
  "description":null
  },
  {
  "id":89,
  "item":"Industrial Metaverse",
  "description":null
  },
  {
  "id":90,
  "item":"Motion Sensors",
  "description":null
  },
  {
  "id":91,
  "item":"Anamorphic Projection",
  "description":null
  },
  {
  "id":92,
  "item":"Projection Mapping",
  "description":null
  }
  ],
  "sectors":[
  {
  "id":41,
  "name":"Augmented Reality",
  "description":"##### Apa itu **AUGMENTED REALITY**?\n*Augmented reality* (AR) adalah teknologi yang meningkatkan persepsi pengguna terhadap dunia nyata dengan menempatkan informasi digital. Teknologi ini menggunakan kombinasi *hardware* dan *software* untuk menempatkan gambar digital, teks, dan data lainnya di pandangan pengguna dalam dunia nyata. Ini dapat dilakukan melalui berbagai perangkat, termasuk *smartphone*, *tablet*, dan *headset* AR khusus. Tim kami dapat membantu Anda memanfaatkan kekuatan teknologi AR untuk brand Anda dan membangun dunia digital yang baru. Dengan pengalaman dalam mengelola berbagai proyek, mulai dari bidang hiburan hingga ritel, kami dapat membantu Anda memberikan pengalaman yang melampaui ekspektasi Anda. Apapun yang ingin Anda bangun, kami akan mewujudkannya.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":42,
  "name":"Virtual Reality",
  "description":"##### apa itu **VIRTUAL REALITY**?\n*Virtual reality* (VR) adalah teknologi yang menciptakan lingkungan simulasi yang memungkinkan pengguna berinteraksi secara nyata. Teknologi ini biasanya memerlukan penggunaan *headset* khusus, seperti *Oculus* atau *Vive*, yang melacak gerakan kepala pengguna dan menampilkan gambar pada layar di depan mata mereka. Pengguna sepenuhnya terlibat dalam lingkungan virtual dan dapat berinteraksi dengannya menggunakan pengontrol tangan atau perangkat lainnya. Tim kami dapat mengembangkan solusi teknologi yang tepat guna untuk memenuhi kebutuhan Anda dalam era digital baru ini. Kami bangga dapat membantu Anda dalam menciptakan serta mengembangkan strategi kampanye VR yang menghasilkan *user engagement* yang nyata serta hasil yang optimal.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  },
  {
  "id":43,
  "name":"Extended Reality",
  "description":"##### apa itu **EXTENDED REALITY**?\n*Extended reality* (XR) adalah istilah yang mengacu pada kategori teknologi yang meliputi *virtual reality* (VR), *augmented reality* (AR), dan *mixed reality* (MR). XR mencakup berbagai teknologi yang dapat memudarkan batas antara dunia nyata dan dunia maya, serta memungkinkan pengguna untuk berinteraksi dengan konten digital secara lebih imersif dan realistis. XR dapat digunakan dalam berbagai industri, seperti game, pelatihan, pendidikan, kesehatan, visualisasi produk, dan kolaborasi jarak jauh, dan kami menawarkan solusi yang disesuaikan untuk setiap industri.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  },
  {
  "id":44,
  "name":"Customer Metaverse",
  "description":"##### apa itu **CONSUMER METAVERSE**?\n*Consumer Metaverse* adalah istilah yang digunakan untuk menggambarkan dunia virtual di mana pengguna dapat berinteraksi satu sama lain dengan konten digital secara lancar dan imersif. Ini adalah visi dari realitas virtual yang sepenuhnya terwujud di mana pengguna dapat terlibat dalam berbagai kegiatan, seperti bersosialisasi, bermain game, berbelanja, dan banyak lagi. *Consumer Metaverse* pada dasarnya adalah ruang digital di mana para pengguna dapat menjelajahi, membuat, dan terhubung dengan orang lain dengan cara yang mirip di dunia fisik. Layanan kami membantu bisnis Anda untuk membuat dan mengoperasikan dunia virtual Anda sendiri, atau platform untuk konsumen Anda untuk mengakses dan berpartisipasi dalam metaverse.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- ```Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.```\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":45,
  "name":"Industrial Metaverse",
  "description":"##### apa itu **INDUSTRIAL METAVERSE**?\n*Industrial Metaverse* adalah istilah yang digunakan untuk menggambarkan dunia virtual yang dirancang khusus untuk aplikasi industri dan manufaktur. Ini dapat mencakup simulasi virtual, *digital twin*, dan teknologi lain yang memungkinkan perusahaan untuk merancang, menguji, dan mengoptimalkan produk dan proses mereka dalam lingkungan virtual. Dengan mensimulasikan kondisi dan skenario dunia nyata, kami dapat memberikan layanan ini kepada bisnis Anda, sehingga Anda dapat memperoleh *insight* berharga dan membuat keputusan berdasarkan data untuk meningkatkan efisiensi, mengurangi biaya, dan meningkatkan produktivitas.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":46,
  "name":"Motion Sensor",
  "description":"##### apa itu **MOTION SENSOR**?\nSebuah *motion sensor* atau pendeteksi gerak adalah perangkat elektronik yang menggunakan sensor untuk mendeteksi orang atau benda di sekitarnya. Banyak *brand* telah meningkatkan tren teknologi *motion-capture* di beberapa acara melalui instalasi interaktif seperti dinding *pixel* LED atau bahkan terowongan dengan animasi dan musik. Kami dapat membantu brand Anda menggunakan teknologi ini dengan cara yang bermakna bagi pengalaman pengguna Anda.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n"
  },
  {
  "id":47,
  "name":"Anamorphic Projection",
  "description":"##### apa itu *ANAMORPHIC PROJECTION*?\n*Anamorphic projection* adalah bentuk khusus dari pemetaan proyeksi yang menggunakan ilusi optik untuk menciptakan gambar yang terdistorsi dan terlihat normal ketika dilihat dari sudut tertentu. Layanan ini dapat digunakan untuk berbagai aplikasi, seperti periklanan, instalasi seni, atau pengalaman immersif. Dengan memproyeksikan gambar pada permukaan yang tidak beraturan bentuknya, seperti dinding melengkung atau elemen patung, proyeksi anamorfik dapat menciptakan efek visual yang unik dan menambahkan unsur kejutan pada setiap proyeksinya. Secara keseluruhan, proyeksi anamorfik adalah teknik yang sangat menakjubkan secara visual dan dapat menciptakan pengalaman yang tidak terlupakan bagi setiap penonton.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  },
  {
  "id":48,
  "name":"Projection Mapping",
  "description":"##### apa itu **PROJECTION MAPPING**?\n*Projection mapping* adalah teknologi kreatif dan inovatif yang memproyeksikan gambar digital ke objek, bangunan atau permukaan fisik, menciptakan pengalaman visual yang unik. Layanan ini dapat digunakan untuk berbagai tujuan, seperti pemasaran, hiburan, atau ekspresi artistik. Dengan mengubah permukaan biasa menjadi tampilan yang dinamis dan interaktif, *projection mapping* dapat memunculkan tingkat kreativitas serta interaksi baru bagi para audiens. *Projection mapping* dapat digunakan untuk menciptakan efek visual yang menakjubkan, mengubah tampilan dan nuansa sebuah acara atau ruang, atau meningkatkan cerita serta pesan dari sebuah *brand*. Secara keseluruhan, *projection mapping* adalah alat yang serbaguna dan kuat untuk menciptakan pengalaman imersif yang memikat audiens dan meninggalkan kesan yang tak terlupakan.\n  \n  \n```Anda dapat menggunakan layanan ini untuk…```\n- Menumbuhkan kapitalisasi pasar dan nilai perusahaan Anda.\n- Meningkatkan *brand awareness* untuk untuk *brand*, produk, dan layanan Anda.\n- ```Memberikan pengalaman menarik bagi target konsumen Anda.```\n- Melindungi dan menyimpan data berharga Anda dari *Hackers*\n- Meningkatkan produktivitas serta efisiensi operasional Anda.\n\n"
  }
  ],
  "icon":null,
  "localizations":[
  {
  "id":13,
  "locale":"en",
  "published_at":"2022-11-10T08:22:57.001Z"
  }
  ]
  }
]