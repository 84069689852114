import { createContext, useState } from "react";

const AppContext = createContext();

export function AppProvider({children}) {
  const baseUrl = 'http://strp.futurphuture.io';
  const [lang, setLang] = useState("id");
  
  return (
    <AppContext.Provider
      value={{
        baseUrl,
        lang,
        setLang
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext;