export const data_en = {
  "id":1,
  "highlighted_title":"CLIENTS WHO",
  "unhighlighted_title":"PAID US",
  "locale":"en",
  "published_at":"2023-02-13T06:51:29.354Z",
  "created_at":"2023-02-13T06:51:27.420Z",
  "updated_at":"2023-02-13T06:51:29.382Z",
  "media":[
  {
  "id":57,
  "name":"list_brand_2.png",
  "alternativeText":"",
  "caption":"",
  "width":3882,
  "height":1854,
  "formats":{
  "large":{
  "ext":".png",
  "url":"/uploads/large_list_brand_2_de54d2ae00.png",
  "hash":"large_list_brand_2_de54d2ae00",
  "mime":"image/png",
  "name":"large_list_brand_2.png",
  "path":null,
  "size":218.07,
  "width":1000,
  "height":478
  },
  "small":{
  "ext":".png",
  "url":"/uploads/small_list_brand_2_de54d2ae00.png",
  "hash":"small_list_brand_2_de54d2ae00",
  "mime":"image/png",
  "name":"small_list_brand_2.png",
  "path":null,
  "size":85.52,
  "width":500,
  "height":239
  },
  "medium":{
  "ext":".png",
  "url":"/uploads/medium_list_brand_2_de54d2ae00.png",
  "hash":"medium_list_brand_2_de54d2ae00",
  "mime":"image/png",
  "name":"medium_list_brand_2.png",
  "path":null,
  "size":148.15,
  "width":750,
  "height":358
  },
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_list_brand_2_de54d2ae00.png",
  "hash":"thumbnail_list_brand_2_de54d2ae00",
  "mime":"image/png",
  "name":"thumbnail_list_brand_2.png",
  "path":null,
  "size":33.68,
  "width":245,
  "height":117
  }
  },
  "hash":"list_brand_2_de54d2ae00",
  "ext":".png",
  "mime":"image/png",
  "size":855.23,
  "url":"/uploads/list_brand_2_de54d2ae00.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-13T06:51:18.703Z",
  "updated_at":"2023-02-13T06:51:18.754Z"
  },
  {
  "id":58,
  "name":"list_brand_3.png",
  "alternativeText":"",
  "caption":"",
  "width":3882,
  "height":1852,
  "formats":{
  "large":{
  "ext":".png",
  "url":"/uploads/large_list_brand_3_2afe5eea05.png",
  "hash":"large_list_brand_3_2afe5eea05",
  "mime":"image/png",
  "name":"large_list_brand_3.png",
  "path":null,
  "size":215.38,
  "width":1000,
  "height":477
  },
  "small":{
  "ext":".png",
  "url":"/uploads/small_list_brand_3_2afe5eea05.png",
  "hash":"small_list_brand_3_2afe5eea05",
  "mime":"image/png",
  "name":"small_list_brand_3.png",
  "path":null,
  "size":84.97,
  "width":500,
  "height":238
  },
  "medium":{
  "ext":".png",
  "url":"/uploads/medium_list_brand_3_2afe5eea05.png",
  "hash":"medium_list_brand_3_2afe5eea05",
  "mime":"image/png",
  "name":"medium_list_brand_3.png",
  "path":null,
  "size":145.77,
  "width":750,
  "height":358
  },
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_list_brand_3_2afe5eea05.png",
  "hash":"thumbnail_list_brand_3_2afe5eea05",
  "mime":"image/png",
  "name":"thumbnail_list_brand_3.png",
  "path":null,
  "size":33.34,
  "width":245,
  "height":117
  }
  },
  "hash":"list_brand_3_2afe5eea05",
  "ext":".png",
  "mime":"image/png",
  "size":869.07,
  "url":"/uploads/list_brand_3_2afe5eea05.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-13T06:51:18.798Z",
  "updated_at":"2023-02-13T06:51:18.830Z"
  },
  {
  "id":59,
  "name":"list_brand.png",
  "alternativeText":"",
  "caption":"",
  "width":3882,
  "height":1851,
  "formats":{
  "large":{
  "ext":".png",
  "url":"/uploads/large_list_brand_cb2fd090e9.png",
  "hash":"large_list_brand_cb2fd090e9",
  "mime":"image/png",
  "name":"large_list_brand.png",
  "path":null,
  "size":265.96,
  "width":1000,
  "height":477
  },
  "small":{
  "ext":".png",
  "url":"/uploads/small_list_brand_cb2fd090e9.png",
  "hash":"small_list_brand_cb2fd090e9",
  "mime":"image/png",
  "name":"small_list_brand.png",
  "path":null,
  "size":83,
  "width":500,
  "height":238
  },
  "medium":{
  "ext":".png",
  "url":"/uploads/medium_list_brand_cb2fd090e9.png",
  "hash":"medium_list_brand_cb2fd090e9",
  "mime":"image/png",
  "name":"medium_list_brand.png",
  "path":null,
  "size":163.37,
  "width":750,
  "height":358
  },
  "thumbnail":{
  "ext":".png",
  "url":"/uploads/thumbnail_list_brand_cb2fd090e9.png",
  "hash":"thumbnail_list_brand_cb2fd090e9",
  "mime":"image/png",
  "name":"thumbnail_list_brand.png",
  "path":null,
  "size":24.83,
  "width":245,
  "height":117
  }
  },
  "hash":"list_brand_cb2fd090e9",
  "ext":".png",
  "mime":"image/png",
  "size":1703.33,
  "url":"/uploads/list_brand_cb2fd090e9.png",
  "previewUrl":null,
  "provider":"local",
  "provider_metadata":null,
  "created_at":"2023-02-13T06:51:19.206Z",
  "updated_at":"2023-02-13T06:51:19.214Z"
  }
  ],
  "localizations":[
  ]
}