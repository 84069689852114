import style from "./GudangKreativ.module.css";
import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { data_en, data_id } from "./data";

export default function GudangKreativ() {
  const [data, setData] = useState();
  const { baseUrl, lang } = useContext(AppContext);
  const fetchData = async () => {
    // const fetchResult = await fetch(`${baseUrl}/gudang-kreativ?_locale=${lang}`);
    // const result = await fetchResult.json();
    const result = lang === "id" ? data_id: data_en;
    setData(result);
  };

  useEffect(() => {
    fetchData();
  }, [lang]);

  return (<>
    {data && <div className={style["container"]}>
      <div className={style["img-wrapper"]}>
        {/* <img src={`${baseUrl}${data.media.url}`} alt="Gudang Kreativ" /> */}
        <img src={`${data.media.url}`} alt="Gudang Kreativ" />
      </div>
      <div className={style["description"]}>
        <ReactMarkdown>
          {data.description}
        </ReactMarkdown>
      </div>
    </div>}
  </>)
}